/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { suspenseWrapper } from 'utils/suspenseWrapper';
import { authWrapper } from 'utils/authWrapper';
import { path } from 'constant/pathLinksConstant';
import {
  AgencyAdminAccessRoles,
  catalogRoles,
  financeRoles,
  subAdminAccessRoles,
  superAdminAccessRoles,
  underwritingRoles
} from 'constant/authRole';
import ErrorPage from 'pages/404';

import { documentTemplateRoute } from './documentTemplateRoute';
import { productsRoute } from './productsRoute';
import { agenenciesRoute } from './agenciesRoute';
import { mgasRoute } from './mgasRoute';
import { carriersRoute } from './carriersRoute';
import { distributionListRoute } from './distributionsRoute';
import { usersRoute } from './usersRoute';
import { agentsRoute } from './agentsRoute';
import { networkRoute } from './networkRoute';
// import { serviceRequestRoute } from './serviceRequestRoute';

export const appRouter = createBrowserRouter([
  {
    path: '/',
    async lazy() {
      const { HomePage } = await import('../pages/home');
      return { Component: () => authWrapper(<HomePage />, 'unAuth') };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.login,
    async lazy() {
      const { LoginPage } = await import('../pages/auth/Login/Login');
      return { Component: () => authWrapper(<LoginPage />, 'unAuth') };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.forgotPassword,
    async lazy() {
      const { ForgotPassword } = await import(
        '../pages/auth/ForgotPassword/ForgotPassword'
      );
      return { Component: () => suspenseWrapper(<ForgotPassword />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.signUp,
    async lazy() {
      const { SignUpPage } = await import('../pages/auth/SignUp/SignUp');
      return { Component: () => suspenseWrapper(<SignUpPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.agentSignup,
    async lazy() {
      const { AgentSignUpPage } = await import(
        '../pages/auth/SignUp/AgentSignup'
      );
      return { Component: () => suspenseWrapper(<AgentSignUpPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.signUpSuccess,
    async lazy() {
      const { SuccessRegistrationPage } = await import(
        '../pages/auth/SignUp/SuccessRegistration'
      );
      return { Component: () => suspenseWrapper(<SuccessRegistrationPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.app.checkEmail,
    async lazy() {
      const { CheckEmail } = await import('../pages/auth/CheckEmail');
      return { Component: () => suspenseWrapper(<CheckEmail />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyUserToken,
    async lazy() {
      const { VerifyUserToken } = await import('../pages/VerifyUserToken');
      return { Component: () => suspenseWrapper(<VerifyUserToken />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyNewAgency,
    async lazy() {
      const { VerifyNewAgency } = await import('../pages/VerifyNewAgency');
      return { Component: () => suspenseWrapper(<VerifyNewAgency />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyConsumerCustomer,
    async lazy() {
      const { VerifyConsumerCustomer } = await import(
        '../pages/VerifyConsumerCustomer'
      );
      return { Component: () => suspenseWrapper(<VerifyConsumerCustomer />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyCustomer,
    async lazy() {
      const { VerifyCustomer } = await import('../pages/VerifyCustomer');
      return { Component: () => suspenseWrapper(<VerifyCustomer />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  

  {
    path: path.app.resetPassword,
    async lazy() {
      const { ResetPassword } = await import('../pages/auth/ResetPassword');
      return { Component: () => suspenseWrapper(<ResetPassword />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.products.root,
    async lazy() {
      const { ProductsPage } = await import('../pages/products');
      return {
        Component: () =>
          authWrapper(<ProductsPage />, 'auth', [...superAdminAccessRoles, ...catalogRoles])
      };
    },
    children: productsRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.agencies.root,
    async lazy() {
      const { AgenciesPage } = await import('../pages/agencies');
      return {
        Component: () =>
          authWrapper(<AgenciesPage />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles, ...financeRoles, underwritingRoles[1], catalogRoles[1]])
      };
    },
    children: agenenciesRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.mgas.root,
    async lazy() {
      const { MgasPage } = await import('../pages/mgas');
      return {
        Component: () =>
          authWrapper(<MgasPage />, 'auth', [...superAdminAccessRoles, ...catalogRoles])
      };
    },
    children: mgasRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.rating.newRating,
    async lazy() {
      const { NewRatingSystem } = await import(
        '../pages/mgas/RatingSystems/NewRatingSystem'
      );
      return {
        Component: () =>
          authWrapper(<NewRatingSystem />, 'auth', superAdminAccessRoles)

        // Component: () => <NewRatingSystem />
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.rating.updateRating,
    async lazy() {
      const { NewRatingSystem } = await import(
        '../pages/mgas/RatingSystems/NewRatingSystem'
      );
      return {
        Component: () =>
          authWrapper(<NewRatingSystem />, 'auth', superAdminAccessRoles)
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.carriers.root,
    async lazy() {
      const { CarriersPage } = await import('../pages/carriers');
      return {
        Component: () =>
          authWrapper(<CarriersPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: carriersRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  
  {
    path: path.distribution.list,
    async lazy() {
      const { DistributionManagement } = await import('../pages/DistributionManagement');
      return {
        Component: () =>
          authWrapper(<DistributionManagement />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles, ...underwritingRoles])
      };
    },
    children:distributionListRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },


  {
    path: path.network.root,
    async lazy() {
      const { NetworkPage } = await import('../pages/network');
      return {
        Component: () =>
          authWrapper(<NetworkPage />, 'auth', superAdminAccessRoles)
      };
    },
    children: networkRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.users.root,
    async lazy() {
      const { UsersPage } = await import('../pages/users');
      return {
        Component: () =>
          authWrapper(<UsersPage />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles])
      };
    },
    children: usersRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.agents.root,
    async lazy() {
      const { AgentsPage } = await import('../pages/agents');
      return {
        Component: () =>
          authWrapper(<AgentsPage />, 'auth', AgencyAdminAccessRoles)
      };
    },
    children: agentsRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/form-builder',
    async lazy() {
      const { FormBuilderPage } = await import('../pages/FormBuilder');
      return { Component: () => authWrapper(<FormBuilderPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/success-payment/quote/:applicationId/:submissionResponseId/:paymentMethod',
    async lazy() {
      const { PaymentSuccessPage } = await import('../pages/PaymentSuccess');
      return { Component: () => authWrapper(<PaymentSuccessPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/verify-application',
    async lazy() {
      const { VerifyApplicationPage } = await import('../pages/verify');
      return { Component: () => <VerifyApplicationPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/mga-app-approval',
    async lazy() {
      const { ApprovalPage } = await import('../pages/approval');
      return { Component: () => <ApprovalPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: '/upload-policy',
    async lazy() {
      const { ApprovalPage } = await import('../pages/approval');
      return { Component: () => <ApprovalPage /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.documentTemplate.root,
    async lazy() {
      const { TemplatesPage } = await import('../pages/documentTemplate');
      return {
        Component: () =>
          authWrapper(<TemplatesPage />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles, ...catalogRoles])
      };
    },
    children: documentTemplateRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  // {
  //   path: path.documentTemplate.tagTemplate,
  //   async lazy() {
  //     const  { TagTemplate } = await import("../pages/documentTemplate/TagTemplate/TagTemplate");
  //     return { Component: () => suspenseWrapper(<TagTemplate />) };
  //   }
  // },

  {
    path: path.configuration.root,
    async lazy() {
      const { Configuration } = await import('../pages/ConfigurationPage');
      return {
        Component: () =>
          authWrapper(<Configuration />, 'auth', superAdminAccessRoles)
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  
  {
    path: path.serviceRequests.root,
    async lazy() {
      const { ServiceRequests } = await import('../pages/ServiceRequests/ServiceRequests');
      return {
        Component: () =>
          authWrapper(<ServiceRequests />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles])
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.serviceRequests.add,
    async lazy() {
      const { NewServiceRequest } = await import('../pages/ServiceRequests/NewServiceRequest');
      return { Component: () => authWrapper(<NewServiceRequest />, 'auth', superAdminAccessRoles) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.policies.root,
    async lazy() {
      const { Policies } = await import('../pages/Policies/Policies');
      return { Component: () => authWrapper(<Policies />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles, ...financeRoles, underwritingRoles[1]]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.catalog.questions,
    async lazy() {
      const { Questions } = await import('../pages/catalog/Questions/Questions');
      return { Component: () => authWrapper(<Questions />, 'auth', superAdminAccessRoles) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  
  {
    path: path.catalog.managements,
    async lazy() {
      const { Managements } = await import('../pages/catalog/Managements');
      return { Component: () => authWrapper(<Managements />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  
  {
    path: path.catalog.appetite,
    async lazy() {
      const { Appetite } = await import('../pages/catalog/Appetite/Appetite');
      return { Component: () => authWrapper(<Appetite />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.catalog.industries,
    async lazy() {
      const { Industries } = await import('../pages/catalog/Industries/Industries');
      return { Component: () => authWrapper(<Industries />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.manageApplications.dictonary,
    async lazy() {
      const { Dictonary } = await import('../pages/manageApplications/Dictonary/Dictonary');
      return { Component: () => authWrapper(<Dictonary />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.manageApplications.applications,
    async lazy() {
      const { Applications } = await import('../pages/manageApplications/Applications/Applications');
      return { Component: () => authWrapper(<Applications />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.manageApplications.newApplication,
    async lazy() {
      const { NewApplication } = await import('../pages/manageApplications/Applications/NewApplication');
      return { Component: () => authWrapper(<NewApplication />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.manageApplications.editApplication,
    async lazy() {
      const { NewApplication } = await import('../pages/manageApplications/Applications/NewApplication');
      return { Component: () => authWrapper(<NewApplication />, 'auth', [...superAdminAccessRoles, ...catalogRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.payments.root,
    async lazy() {
      const { Payments } = await import('../pages/Payments/Payments');
      return { Component: () => authWrapper(<Payments />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  },
  {
    path: path.reports.root,
    async lazy() {
      const { Reports } = await import('../pages/Reports/Reports');
      return { Component: () => authWrapper(<Reports />, 'auth', [...superAdminAccessRoles, ...subAdminAccessRoles, ...underwritingRoles, ...financeRoles]) };
      
    },
    errorElement: suspenseWrapper(<ErrorPage />)

  }
]);
