export const ADMIN_CONSTANT = {
  MAIN_MENU: 'MAIN MENU',
  ACCEPTED_BY_VISA: 'accepted_by_mga',
  APPLICATION_ID: 'applicationId',
  COMPANY_ID: 'companyId',
  REQUEST_POLICY: 'request policy',
  POLICY_RECEIVED: 'policy received',
  PENDING: 'pending',
  PRODUCT_ID: 'productName',
  PRODUCT_LABEL: 'PRODUCT NAME',
  STATUS_ID: 'status',
  APPLICATION_LABEL: 'APPLICATION STATUS',
  VERSION_ID: 'version',
  VERSION_LABEL: 'APPLICATION VERSION',
  STATE_ID: 'state',
  STATE_LABEL: 'STATE',
  ACTION_ID: 'action',
  ACTION_LABEL: 'ACTIONS',
  MGA: 'MGA',
  CARRIER: 'CARRIER',
  AGENCY: 'AGENCY',
  APP_DOC_OR_INVOICE_ERROR: 'You need to upload the appDocUrl',
  UNDER_REVIEW: 'Under_review',
  SELECT_PAYMENT_PLAN: 'Select Payment Plan',
  CREDIT_CARD: 'Credit Card',
  PAY_NOW: 'Pay Now',
  EDIT:'edit',
  CREATE:'create',
  ACTIVE:'active',
  INACTIVE:'inactive',
  USER_DEFINED_VALUES:'USER_DEFINED_VALUES',
  API_DEFINED_VALUES:'API_DEFINED_VALUES',
  NO_DATA_FOUND: 'No data available',
  INDICATION_CARRIER_FORM:'Indication Carrier Form',
  INDICATION_COMMON_FORM:'Indication Common Form'
};
