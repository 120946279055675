import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { PageLoader } from 'components';
import { fallbackRoute } from 'constant/authRole';
import { path } from 'constant/pathLinksConstant';
import { selectLoaderState } from 'redux/modules';

import { useAuth } from '../hooks/useAuth';

export const ProtectedRoute = ({ children, state, allowedRoles }) => {

  const { user } = useAuth();
  const loaderState = useSelector(selectLoaderState);
  if (user && state === 'auth' && allowedRoles && allowedRoles.length > 0) {
    if (allowedRoles.includes(user?.role?.slug)) {
      return children;
    }
    const fallBack = fallbackRoute[user?.role?.slug];
    return <Navigate to={fallBack} replace />;
  }

  // eslint-disable-next-line eqeqeq
  if (!user && state === 'auth') {
    return <Navigate to={path.app.login} replace />;
  }

  return (
    <>
      {loaderState && loaderState.loading && (
        <PageLoader dark={loaderState.dark} />
      )}
      {children}
    </>
  );
};
