import React, { useRef } from 'react';
import { Typography, Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAwsS3 } from 'hooks/useAwsS3';
import pdfBg from 'assets/icons/pdf-bg.png';
import pdfIcon from 'assets/icons/pdf.png';
import { selectconfigDataState } from 'redux/modules/config';

export function ImageFileUploader({ filePath, onFileUpload, setFilePath, fileLabel, accept }) {
  const fileInputRef = useRef(null);
  const configData = useSelector(selectconfigDataState);

  const { uploadFile } = useAwsS3();
  const { t } = useTranslation();



  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    const fileUrl = uploadFile(file, configData?.bucketFolders?.S3_BUCKET_COMPANY_LOGO_PATH);

    fileUrl
      .then((fileS3Url) => {
        onFileUpload(fileS3Url);
      })
      .catch(() => {
        onFileUpload(null);
      });
  };
  const handleDeletePdf = () => {
    setFilePath(null);
  };

  function setSrc() {
    if (filePath && filePath.includes('.pdf')) {
      return pdfIcon;
    } if (filePath && filePath !== null) {
      return filePath;
    }
    return pdfBg;

  }

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Box
          sx={{
            width: 72,
            height: 72,
            bgcolor: '#F5F6F7',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            my: 1,
            cursor: 'pointer',
            borderRadius: '8px'
          }}
          onClick={handleFileInput}
        >
          <img
            src={setSrc()}
            width="36"
            alt="PDF Icon"
            style={{ marginRight: '5px' }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Box ml={2} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body1" fontWeight="600">
            {t(fileLabel)}
          </Typography>
          <Typography variant="caption" color="#7D828B">
            ({t('formLabel.maxSize')})
          </Typography>
          {filePath === null && (
            <>
              <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
                accept={accept || '"application/pdf,application/vnd.ms-excel"'}
                onChange={handleFileSelection}
              />
              <Button
                onClick={handleFileInput}
                variant="text"
                sx={{
                  textTransform: 'none',
                  fontSize: 16,
                  justifyContent: 'left'
                }}
              >
                {t('buttonLabel.browseFile')}
              </Button>
            </>
          )}
        </Box>

        {filePath !== null && (
          <Grid>
            <Typography
              sx={{
                p: 2,
                width: '100%',
                paddingBottom: 0,
                color: '#18AEE2'
              }}
              id="tableTitle"
              component="span"
              variant="subtitle2"
            >
              <a
                href={filePath}
                style={{
                  cursor: 'pointer',
                  color: '#18AEE2',
                  fontWeight: '700'
                }}
                target="_blank"
                rel="noreferrer"
              >
                {t('modelTitle.viewFile')}
              </a>
            </Typography>
            |
            <Button
              sx={{ textTransform: 'none', color: 'red' }}
              onClick={handleDeletePdf}
              variant="text"
            >
              {t('buttonLabel.remove')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
