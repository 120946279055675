/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from '@amelix/react-lottie';

import animation from 'assets/Main.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation
};

const AnimatedLoader = ({height, width}) => (
  <Lottie options={defaultOptions} height={height || 150} width={width || 150} />
);

export default AnimatedLoader;
