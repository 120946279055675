/* eslint-disable react/button-has-type */
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from 'theme';
import { appRouter } from 'routes/appRoute';
import { AuthProvider } from 'context/AuthProvider';
import { setConfigData, useGetConfigDataQuery } from 'redux/modules/config';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { store } from './redux';

function InnerApp() {
  const { data: configData } = useGetConfigDataQuery();
  const dispatch = useDispatch();

  const [buildId, setBuildId] = useLocalStorage('buildId', null);
  

  useEffect(() => {
    if (configData) {
      if(!buildId) {
        setBuildId(configData.buildId);
        dispatch(setConfigData(configData));
      }
      else if(buildId && buildId !== configData.buildId){
        localStorage.clear();
        setBuildId(configData.buildId);
        window.location.reload(true);
      }
      else dispatch(setConfigData(configData));
    }
  }, [configData]);

  return <RouterProvider router={appRouter} />;
}

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <InnerApp />
          </Provider>
        </ThemeProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
