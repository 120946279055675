/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Lottie from '@amelix/react-lottie';

import animation from '../../assets/loader.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation
};

const FetchingLaoder = () => (
    <Lottie options={defaultOptions} height={30} width={50} />
);

export default FetchingLaoder;
