import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const dictonaryServiceApi = createApi({
  reducerPath: 'dictonaryServiceApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getSearchedListDictonary: builder.query({
      query: (payload) => ({
        url: payload.url,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDictonaryApplicationList: builder.query({
      query: (payload) => ({
        url: `dictionary-service/manage-applications/dictionary-form/get-forms?productId=${payload.productId}&carrierId=${payload.carrierId}&searchBy=${payload.searchBy}&naicsCode=${payload.naicsCode}&limit=${payload.limit === -1 ? 200 : payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })

    }),
    getDictonaryDetails: builder.query({
      query: (payload) => ({
        url: `dictionary-service/manage-applications/dictionary/get?keyName=${payload.keyName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getDictonaryFormPreview: builder.query({
      query: (payload) => ({
        url: `dictionary-service/manage-applications/dictionary-form/form-preview?applicationFormId=${payload.applicationFormId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getDictonaryFormDetails: builder.query({
      query: (payload) => ({
        url: payload.carrierId ? `dictionary-service/manage-applications/dictionary-form/form-details?productId=${payload.productId}&carrierId=${payload.carrierId}&applicationFormId=${payload.applicationFormId}` : `dictionary-service/manage-applications/dictionary-form/form-details?productId=${payload.productId}&&applicationFormId=${payload.applicationFormId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getDependentApps: builder.query({
      query: (payload) => ({
        url: `dictionary-service/manage-applications/dictionary/get-dependent-apps?keyName=${payload.keyName}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDictonaryKeys: builder.query({
      query: (payload) => ({
        url: `/dictionary-service/manage-applications/dictionary-form/get-keys?productId=${payload.productId}&carrierId=${payload.carrierId}&searchBy=${payload.searchBy}&type=${payload.type}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getDictonarySections: builder.query({
      query: (payload) => ({
        url: `/dictionary-service/manage-applications/dictionary/search-default-sections?limit=100&offset=0&keyName=${payload.keyName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    updateDictonaryStatus: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary/status',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addDictonaryData: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary/add',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    saveDictonaryData: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary-form/save',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    arrangeDictonaryData: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary-form/arrange-form',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    deleteDictonaryData: builder.mutation({
      query: (payload) => ({
        url:payload.url,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateDictonaryData: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateExternalKey: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/manage-applications/dictionary/update/externally_required',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })

  })
});

export const {
  useGetDictonaryDetailsQuery, 
  useLazyGetSearchedListDictonaryQuery,
  useUpdateDictonaryStatusMutation,
  useGetDependentAppsQuery,
  useUpdateDictonaryDataMutation,
  useAddDictonaryDataMutation,
  useGetDictonaryApplicationListQuery, 
  useGetDictonaryKeysQuery,
  useSaveDictonaryDataMutation,
  useDeleteDictonaryDataMutation,
  useGetDictonaryFormDetailsQuery,
  useLazyGetDictonarySectionsQuery,
  useArrangeDictonaryDataMutation,
  useGetDictonaryFormPreviewQuery,
  useUpdateExternalKeyMutation
} = dictonaryServiceApi;
