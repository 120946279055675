/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Typography, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ApplicationActive from 'assets/nav-icons/application-active.svg';
import ApplicationInactive from 'assets/nav-icons/application-inactive.svg';
import Products from 'assets/nav-icons/products.svg';
import ProductsActive from 'assets/nav-icons/products-active.svg';
import Agencies from 'assets/nav-icons/agencies.svg';
import AgenciesActive from 'assets/nav-icons/agencies-active.svg';
import MGAs from 'assets/nav-icons/mgas.svg';
import MGAsActive from 'assets/nav-icons/mgas-active.svg';
import PaymentsIconActive from 'assets/nav-icons/payments-active.svg';
import PaymentsIcon from 'assets/nav-icons/payments.svg';
import DocumentTextActive from 'assets/nav-icons/document-text-active.svg';
import DocumentTextInActive from 'assets/nav-icons/document-text-inactive.svg';
import DistributionActive from 'assets/nav-icons/distribution_active.svg';
import Distribution from 'assets/nav-icons/distribution.svg';
import Quote from 'assets/nav-icons/quotes.svg';
import CatalogActive from 'assets/nav-icons/catalog-active.svg';
import CatalogInActive from 'assets/nav-icons/catalog-inactive.svg';
import QuoteActive from 'assets/nav-icons/quotes-active.svg';
import dollarSquare from 'assets/nav-icons/dollar-square.svg';
import dollarSquareActive from 'assets/nav-icons/dollar-square-active.svg';
import Users from 'assets/nav-icons/users.svg';
import ServiceIcon from 'assets/nav-icons/service.svg';
import PoliciesIcon from 'assets/nav-icons/policies.svg';
import PoliciesIconActive from 'assets/nav-icons/policies-active.svg';
import ServiceIconActive from 'assets/nav-icons/service-active.svg';
import UsersActive from 'assets/nav-icons/users-active.svg';
import { useAuth } from 'hooks/useAuth';
import { ADMIN_CONSTANT } from 'constant/adminConstant';
import { hasChildren } from 'utils/hasChildren';
import { AUTH_ROLES } from 'constant/authRole';

import { path } from '../../constant/pathLinksConstant';
import { MultiLevel } from './MultiLevel';
import { SingleLevel } from './SingleLevel';

export function NavBar() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const portalUserMenuRoles = user?.portalUserMenuRoles;

  const [isDisabled, setIsDisabled] = React.useState(true);

  const navAgencyEmp = [
    {
      title: t('navigation.quote'),
      icon: Quote,
      iconActive: QuoteActive,
      link: path.quote.root
    }
  ];

  if (user.isParent) {
    navAgencyEmp.push({
      title: t('navigation.partners'),
      icon: Agencies,
      iconActive: AgenciesActive,
      link: path.agencies.root
    });
  } else {
    navAgencyEmp.push({
      title: t('navigation.commissions'),
      icon: dollarSquare,
      iconActive: dollarSquareActive,
      link: path.agencies.authProducts
    });
  }

  const navSuperAdmin = [

    {
      title: t('navigation.distribution'),
      icon: Distribution,
      iconActive: DistributionActive,
      link: path.distribution.list,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'distribution'.toLowerCase()
      )
          },
    {
      title: t('navigation.serviceRequests'),
      icon: ServiceIcon,
      iconActive: ServiceIconActive,
      link: path.serviceRequests.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'SERVICE_REQUESTS'.toLowerCase()
      )
          },
    {
      icon: CatalogInActive,
      iconActive: CatalogActive,
      link: path.catalog.root,
      title: 'Catalog',
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'catalog'.toLowerCase()
      ),
      items: [
        { title: 'Industries', link: path.catalog.industries },
        { title: 'Appetite Rules', link: path.catalog.appetite },
        {
          title: t('navigation.product'),
          icon: Products,
          iconActive: ProductsActive,
          link: path.products.root
        },
        {
          title: t('navigation.mga'),
          icon: MGAs,
          iconActive: MGAsActive,
          link: path.mgas.root
        }
      ]
    },
    {
      icon: ApplicationInactive,
      iconActive: ApplicationActive,
      link: path.manageApplications.root,
      title: 'Manage Applications',
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'MANAGE_APPLICATIONS'.toLowerCase()
      ),
      items: [
        { title: 'Dictionary', link: path.manageApplications.dictonary },
        { title: 'Applications', link: path.manageApplications.applications },
        { title: 'Change History Logs', link: path.manageApplications.changeHistoryLogs }
      ]
    },

    // {
    //   title: t('navigation.payments'),
    //   icon: PaymentsIcon,
    //   iconActive: PaymentsIconActive,
    //   link: path.payments.root,
    //   isDisabled: !portalUserMenuRoles.some(
    //     (role) => role.portalMenuSlug.toLowerCase() === 'payments'.toLowerCase()
    //   )
    // },

    {
      title: t('navigation.reports'),
      icon: PaymentsIcon,
      iconActive: PaymentsIconActive,
      link: path.reports.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'reports'.toLowerCase()
      )
    },
    {
      title: t('navigation.policies'),
      icon: PoliciesIcon,
      iconActive: PoliciesIconActive,
      link: path.policies.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'policies'.toLowerCase()
      )
    },
    // {
    //   title: t('navigation.documentMarker'),
    //   icon: PoliciesIcon,
    //   iconActive: PoliciesIconActive,
    //   link: path.documentMarker.root
    // },

    {
      title: t('navigation.partners'),
      icon: Agencies,
      iconActive: AgenciesActive,
      link: path.agencies.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'agencies'.toLowerCase()
      )
    },

    // {
    //   title: t('navigation.carriers'),
    //   icon: Carrier,
    //   iconActive: CarrierActive,
    //   link: path.carriers.root
    // },
    {
      title: t('navigation.user'),
      icon: Users,
      iconActive: UsersActive,
      link: path.users.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'user'.toLowerCase()
      )
    },
    {
      title: t('navigation.documentTemplate'),
      icon: DocumentTextInActive,
      iconActive: DocumentTextActive,
      link: path.documentTemplate.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'DOCUMENT_TEMPLATE'.toLowerCase()
      )
    },
    {
      title: t('navigation.configuration'),
      icon: Products,
      iconActive: ProductsActive,
      link: path.configuration.root,
      isDisabled: !portalUserMenuRoles.some(
        (role) => role.portalMenuSlug.toLowerCase() === 'configuration'.toLowerCase()
      )
    }
  ];

  let navigation;

  switch (user.role.slug) {
    case 'PORTAL_ADMIN':
    case 'PORTAL_SUB_ADMIN':
    case 'PORTAL_EMPLOYEE':
    case 'MGA_ADMIN':
    case 'MGA_EMPLOYEE':
    case 'AGENCY_CUSTOMER':
    case 'UNDERWRITING_ANALYST':
    case 'UNDERWRITING_MANAGER':
    case 'CUSTOMER_USER':
    case 'CATALOG_ANALYST':
    case 'CATALOG_MANAGER':
    case 'FINANCE_ANALYST':
    case 'FINANCE_MANAGER':
      navigation = navSuperAdmin;
      break;

    case 'AGENCY_ADMIN':
      navigation = navAgencyEmp;
      break;
    case 'AGENCY_EMPLOYEE':
    default:
      navigation = navAgencyEmp;
  }

  React.useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  });

  return (
    <>
      <Typography
        variant="body1"
        sx={{ marginBottom: '0.5rem', fontWeight: 700 }}
      >
        {ADMIN_CONSTANT.MAIN_MENU}
      </Typography>
      <List>
        {navigation.map((nav) => {
          const Component = hasChildren(nav) ? MultiLevel : SingleLevel;
          return (
            <Component
              key={nav.title}
              isDisabled={ isDisabled}
              isMenuDisabled={ user?.role.slug === AUTH_ROLES.PORTAL_ADMIN ? false :  nav.isDisabled}
              item={nav}
            />
          );
        })}
      </List>
    </>
  );
}
