import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getPaymentList: builder.query({
      query: (payload) => ({
        url: `/payment/get-distribution-payment-list-v2?limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}&applicationId=${payload.applicationId}&searchBy=${payload.searchBy}&customerCompanyId=${payload.accountId}&policyId=${payload.policyId}&mgaCompanyId=${payload.carrierId}&productId=${payload.productId}&paymentStatus=${payload.paymentStatus}&type=${payload.type}&companyId=${payload.companyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPaymentDetails: builder.query({
      query: (payload) => ({
        url: `/payment/get-single-payment-transaction-v2?paymentTransactionId=${payload.paymentTransactionId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addPayment: builder.mutation({
      query: (payload) => ({
        url: '/payment/add-new-payment-transaction-v2',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updatePayment: builder.mutation({
      query: (payload) => ({
        url: '/payment/update-payment-transaction-v2',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useAddPaymentMutation,
  useGetPaymentListQuery,
  useLazyGetPaymentDetailsQuery,
  useUpdatePaymentMutation
} = paymentApi;

