/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grow from '@mui/material/Grow';
import { Box } from '@mui/material';

import AnimatedLoader from 'components/Loader/AnimatedLoader';

import { ModalTitle } from './ModalTitle';

const CustomModal = styled(Dialog)(({ theme, noPadding, width }) => ({
  '& .MuiDialogContent-root': {
    paddingLeft: !noPadding ? theme.spacing(3) : 0,
    paddingRight: !noPadding ? theme.spacing(3) : 0,
    paddingBottom: 0,
    bgcolor:'red'
  },
  '& .MuiDialogActions-root': { padding: !noPadding ? theme.spacing(3) : 0 },
  '& .MuiPaper-root': { borderRadius: 8, width }
}));

const Transition = React.forwardRef((props, ref) => (
  <Grow ref={ref} {...props} />
));

export function Modal({
  open,
  onClose,
  title,
  content,
  action,
  isLoading,
  titleSubHeading,
  titleImg,
  noPadding,
  maxWidth,
  width,

  ...props
}) {
  return (
    <CustomModal
      onClose={onClose}
      noPadding={noPadding}
      TransitionComponent={Transition}
      open={open}
      fullWidth
      width={width}
      maxWidth={maxWidth || '100%'}
      disableEnforceFocus
      {...props}
      componentsProps={{ backdrop: { style: { backgroundColor: "#0B1524B2" } } }}
    >
      {isLoading ? (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: 400,
            width: '100%'
          }}
        >
          <AnimatedLoader />
        </div>
      ) : (
        <>
          <ModalTitle id="customized-dialog-title" onClose={onClose}>
            {titleImg && titleSubHeading ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                {titleImg}
                <Box>
                  {title && typeof title === 'object' && React.isValidElement(title) ? (
                    // Render title as a React element (object)
                    title
                  ) : (
                    // Render title as text
                    <div>{title}</div>
                  )}
                  {titleSubHeading}
                </Box>
              </Box>
            ) :
              title && typeof title === 'object' && React.isValidElement(title) ? (
                // Render title as a React element (object)
                title
              ) : (
                // Render title as text
                <div>{title}</div>
              )
            }
          </ModalTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>{action}</DialogActions>
        </>
      )}
    </CustomModal>
  );
}


