import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const distributionsApi = createApi({
  reducerPath: 'distributionsApi',
  tagTypes: ['POST'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDistributionList: builder.query({
      query: (payload) => ({
        url: `/distribution/get?limit=${payload.limit}&offset=${payload.offset}&selectedSearch=${payload.filterBy}&search=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionDataList: builder.query({  
      query: (payload) => ({
        url: `/distribution-v2/get-distributions?limit=${payload.limit}&offset=${payload.offset}&status=${payload.status}&agencyId=${payload.agencyId}&agentId=${payload.agentId}&carrierId=${payload.carrierId}&applicationId=${payload.applicationId}&archived=${payload.archived}&active=${payload.active}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionDetails: builder.query({  
      query: (payload) => ({
        url: `/distribution-v2/get-distribution-detail?applicationId=${payload.appId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionDetailsDataCollection: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-application-details-data-collection?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getUploadedDocuments: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-uploaded-documents?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getDistributionIndicationRequest: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-indication-requests?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionIndication: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-indications?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getJsonResponse: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-json-response?submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getJsonRequest: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-json-request?submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuoteRequests: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-quote-requests?applicationId=${payload.applicationId}&offset=0&limit=10`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getReviewQuotes: builder.query({  
      query: (payload) => ({
        url: `/distribution-operations/get-review-quotes?applicationId=${payload.applicationId}&offset=0&limit=10`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    
    getBindingList: builder.query({
      query: (payload) => ({
        url: `/distribution/get-app-bind-data?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getIndicationCarriers: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indications-carrier?applicationId=${payload.applicationId}&productId=${payload.productId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    referToMgaApplication: builder.query({
      query: (payload) => ({
        url: `/distribution/pre-refer-to-mga?applicationId=${payload.id}&mgaName=${payload.mgaName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    updateAuthorizeApplication: builder.mutation({
      query: (payload) => ({
        url: '/distribution/authorize-application',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateDistributionReferMga: builder.mutation({
      query: (payload) => ({
        url: '/distribution/refer-to-mga',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateAdditionalInfo: builder.mutation({
      query: (payload) => ({
        url: '/distribution/request-additional-info',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateMgaApproval: builder.mutation({
      query: (payload) => ({
        url: '/distribution/mga-approval',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getMgaScreenInfo: builder.query({
      query: (payload) => ({
        url: `/distribution/get-mga-screen-info?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPolicyList: builder.query({
      query: (payload) => ({
        url: `/distribution/policy/get-policy-info?applicationId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPolicyEmail: builder.query({
      query: (payload) => ({
        url: `/distribution/get-policy-request-email?applicationId=${payload.id}&mgaName=${payload.mgaName}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    sendPolicyRequestEmail: builder.mutation({
      query: (payload) => ({
        url: '/distribution/send-policy-request-email',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getMgaSubmission: builder.query({
      query: (payload) => ({
        url: `/distribution/get-mga-submissions?limit=${payload.limit}&offset=${payload.offset}&applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getDistributionPayment: builder.query({
      query: (payload) => ({
        url: `/payment/get-distribution-payment-list-v2?applicationId=${payload.applicationId}&submissionResponseId=${payload.submissionResponseId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPaymentQuoteList: builder.query({
      query: (payload) => ({
        url: `/distribution/get-payment-quote-list?applicationId=${payload.applicationId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getCompanyListByType: builder.query({
      query: (payload) => ({
        url: `/distribution/get-company-list-by-type?type=${payload.type}&submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        },
        transformResponse: (response) => response.result
      })
    }),
    approvePayment: builder.mutation({
      query: (payload) => ({
        url: '/payment/distribution-approve-payment',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    getApplicationForms: builder.query({
      query: (payload) => ({
        url: `/distribution/get-app-forms?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuoteIndication: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indications?applicationId=${payload.applicationId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuoteIndicationDetail: builder.query({
      query: (payload) => ({
        url: `/distribution/get-indication-detail?submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    addQuoteIndicationDetail: builder.mutation({
      query: (payload) => ({
        url: `/distribution/add-indications`,
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['POST']
    }),
    addNewTransaction: builder.mutation({
      query: (payload) => ({
        url: `/payment/add-new-payment-transaction-v2`,
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['POST']
    }),
    updatePaymentTransaction: builder.mutation({
      query: (payload) => ({
        url: `/payment/update-payment-transaction-v2`,
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateQuoteIndicationDetail: builder.mutation({
      query: (payload) => ({
        url: `/distribution/update-indications`,
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    refreshQuote: builder.mutation({
      query: (payload) => ({
        url: `/distribution/refresh-quote`,
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    
    getDistributionProductDetail: builder.query({
      query: (payload) => ({
        url: `/distribution/get-products-detail?applicationId=${payload.appId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getCustomForms: builder.query({
      query: (payload) => ({
        url: `/application/custom-form/list?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getSingleCustomForm: builder.query({
      query: (payload) => ({
        url: `/application/custom-form/get-single-custom-form?customApplicationFormId=${payload.customApplicationFormId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getSinglePaymentTransaction: builder.query({
      query: (payload) => ({
        url: `/payment/get-single-payment-transaction-v2?paymentTransactionId=${payload.paymentTransactionId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }), 
      // response transform
      transformResponse: (response) => response.result
    }),
    
    getOwnersList: builder.query({
      query: (payload) => ({
        url: `/distribution-operations/get-owners-list?limit=10`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    
    getDistributionSummary: builder.query({
      query: (payload) => ({
        url: `/distribution-operations/get-application-summary?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    updateApplicationSummary: builder.mutation({
      query: (payload) => ({
        url: '/distribution-operations/update-application-summary',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    copyCustomFormLink: builder.mutation({
      query: (payload) => ({
        url: '/distribution-operations/copy-custom-form-link',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    archivedApplcation: builder.mutation({
      query: (payload) => ({
        url: '/new-application/archive',
        method: 'Post',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});
export const {
  useGetDistributionListQuery,
  useGetDistributionDataListQuery,
  useGetDistributionDetailsQuery,
  useGetBindingListQuery,
  useReferToMgaApplicationQuery,
  useUpdateAuthorizeApplicationMutation,
  useUpdateDistributionReferMgaMutation,
  useSendPolicyRequestEmailMutation,
  useUpdateAdditionalInfoMutation,
  useUpdateMgaApprovalMutation,
  useGetMgaScreenInfoQuery,
  useGetPolicyListQuery,
  useGetPolicyEmailQuery,
  useGetMgaSubmissionQuery,
  useGetDistributionPaymentQuery,
  useGetApplicationFormsQuery,
  useGetQuoteIndicationQuery,
  useGetQuoteIndicationDetailQuery,
  useUpdateQuoteIndicationDetailMutation,
  useRefreshQuoteMutation,
  useAddQuoteIndicationDetailMutation,
  useGetIndicationCarriersQuery,
  useGetDistributionProductDetailQuery,
  useGetDistributionDetailsDataCollectionQuery,
  useGetDistributionIndicationRequestQuery,
  useLazyGetJsonRequestQuery,
  useLazyGetJsonResponseQuery,
  useGetDistributionIndicationQuery,
  useGetQuoteRequestsQuery,
  useGetReviewQuotesQuery,
  useGetUploadedDocumentsQuery, 
  useGetDistributionSummaryQuery,
  useApprovePaymentMutation,
  useGetPaymentQuoteListQuery,
  useGetCompanyListByTypeQuery,
  useAddNewTransactionMutation,
  useUpdatePaymentTransactionMutation,
  useGetSinglePaymentTransactionQuery,
  useGetOwnersListQuery,
  useUpdateApplicationSummaryMutation,
  useArchivedApplcationMutation,
  useCopyCustomFormLinkMutation,
  useGetCustomFormsQuery,
  useGetSingleCustomFormQuery
} = distributionsApi;
