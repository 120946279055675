import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const productsRoute = [
  {
    path: '',
    async lazy() {
      const { ProductsPage } = await import('../pages/products/All/Products');
      return { Component: () => suspenseWrapper(<ProductsPage />) };
    }
  },
  {
    path: 'config-data/:id',
    async lazy() {
      const { ConfigData } = await import(
        '../pages/products/Applications/ConfigData'
      );
      return { Component: () => suspenseWrapper(<ConfigData />) };
    }
  },
  {
    path: 'manage-applcations/config-data/:id',
    async lazy() {
      const { ConfigData } = await import(
        '../pages/manageApplications/Applications/ConfigData'
      );
      return { Component: () => suspenseWrapper(<ConfigData />) };
    }
  },
  {
    path: 'applications/all',
    async lazy() {
      const { AllApplications } = await import(
        '../pages/products/Applications/AllApplications'
      );
      return { Component: () => suspenseWrapper(<AllApplications />) };
    },

    children: [
      {
        path: 'create',
        async lazy() {
          const { NewApplication } = await import(
            '../pages/products/Applications/NewApplication'
          );
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewApplication } = await import(
            '../pages/products/Applications/NewApplication'
          );
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      }
    ]
  },
  {
    path: 'product-applications',
    async lazy() {
      const { ApplicationsPage } = await import(
        '../pages/products/Applications/Applications'
      );
      return { Component: () => suspenseWrapper(<ApplicationsPage />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewApplication } = await import(
            '../pages/products/Applications/NewApplication'
          );
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewApplication } = await import(
            '../pages/products/Applications/NewApplication'
          );
          return { Component: () => suspenseWrapper(<NewApplication />) };
        }
      }
    ]
  },
  {
    path: 'carrier-applications',
    async lazy() {
      const { CarrierApplicationsPage } = await import(
        '../pages/products/Applications/CarrierApplications'
      );
      return { Component: () => suspenseWrapper(<CarrierApplicationsPage />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewCarrierApplication } = await import(
            '../pages/products/Applications/NewCarrierApplication'
          );
          return {Component: () => suspenseWrapper(<NewCarrierApplication />)};
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewCarrierApplication } = await import(
            '../pages/products/Applications/NewCarrierApplication'
          );
          return {Component: () => suspenseWrapper(<NewCarrierApplication />)};
        }
      }
    ]
  },
  {
    path: 'indication-application',
    async lazy() {
      const { CommonQuestion } = await import(
        '../pages/products/Applications/CommonQuestions'
      );
      return { Component: () => suspenseWrapper(<CommonQuestion />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewCommonQuestions } = await import(
            '../pages/products/Applications/NewCommonQuestions'
          );
          return { Component: () => suspenseWrapper(<NewCommonQuestions />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewCommonQuestions } = await import(
            '../pages/products/Applications/NewCommonQuestions'
          );
          return { Component: () => suspenseWrapper(<NewCommonQuestions />) };
        }
      },
      {
        path: 'config-data/:id',
        async lazy() {
          const { ConfigData } = await import(
            '../pages/products/Applications/ConfigData'
          );
          return { Component: () => suspenseWrapper(<ConfigData />) };
        }
      }
    ]
  },
  {
    path: 'indication-form',
    async lazy() {
      const { IndicationForm } = await import(
        '../pages/products/Applications/IndicationForm'
      );
      return { Component: () => suspenseWrapper(<IndicationForm />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewIndicationForm } = await import(
            '../pages/products/Applications/NewIndicationForm'
          );
          return { Component: () => suspenseWrapper(<NewIndicationForm />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewIndicationForm } = await import(
            '../pages/products/Applications/NewIndicationForm'
          );
          return { Component: () => suspenseWrapper(<NewIndicationForm />) };
        }
      }
    ]
  },
  {
    path: 'quote-application',
    async lazy() {
      const { CommonCarrierForm } = await import(
        '../pages/products/Applications/CommonCarrierForm'
      );
      return { Component: () => suspenseWrapper(<CommonCarrierForm />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewCarrierForm } = await import(
            '../pages/products/Applications/NewCarrierForm'
          );
          return { Component: () => suspenseWrapper(<NewCarrierForm />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewCarrierForm } = await import(
            '../pages/products/Applications/NewCarrierForm'
          );
          return { Component: () => suspenseWrapper(<NewCarrierForm />) };
        }
      },
      {
        path: 'config-data/:id',
        async lazy() {
          const { ConfigData } = await import(
            '../pages/products/Applications/ConfigData'
          );
          return { Component: () => suspenseWrapper(<ConfigData />) };
        }
      }
    ]
  },
  {
    path: 'final-form',
    async lazy() {
      const { FinalForm } = await import(
        '../pages/products/Applications/FinalForm'
      );
      return { Component: () => suspenseWrapper(<FinalForm />) };
    },
    children: [
      {
        path: 'create',
        async lazy() {
          const { NewFinalForm } = await import(
            '../pages/products/Applications/NewFinalForm'
          );
          return { Component: () => suspenseWrapper(<NewFinalForm />) };
        }
      },
      {
        path: 'edit/:id',
        async lazy() {
          const { NewFinalForm } = await import(
            '../pages/products/Applications/NewFinalForm'
          );
          return { Component: () => suspenseWrapper(<NewFinalForm />) };
        }
      }
    ]
  }
];
