export const SCREEN = {
  COMPLETED: 'Completed',
  DATA_COLLECTION: 'Data Collection',
  DOWNLOAD_POLICY: 'Download Policy',
  PAYMENT: 'Payment',
  PREPARE_APPLICATION: 'Prepare Application',
  REVIEW_APPLICATION: 'Review Quote',
  BIND_APPLICATION: 'Bind Quote',
  SELECT_PRODUCT: 'Select Product',
  SELECT_QUOTE: 'Select Indication',
  SIGNATURE: 'Signature',
  SIGN_PROPOSAL: 'Sign Proposal'
};

export const CARRIER_STATE = {
  QUOTE: "Quote",
  BIND: "Bind",
  FAILED: "Failed",
  DECLINED: "Declined"
};