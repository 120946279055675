import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUS from './en-US/translation.json';
import enIN from './en-IN/translation.json';
import en from './en/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': { translation: enUS },
      'en-IN': { translation: enIN },
      en: { translation: en }
    },
    lng: 'en-US', // default language
    fallbackLng: 'en-US', // fallback language
    debug: false,
    interpolation: { escapeValue: false }
  });

export default i18n;
