import { path } from './pathLinksConstant';

export const AUTH_ROLES = {
  AGENCY_ADMIN: 'AGENCY_ADMIN',
  AGENCY_EMPLOYEE: 'AGENCY_EMPLOYEE',
  PORTAL_ADMIN: 'PORTAL_ADMIN',
  PORTAL_EMPLOYEE: 'PORTAL_EMPLOYEE',
  MGA_ADMIN: 'MGA_ADMIN',
  MGA_EMPLOYEE: 'MGA_EMPLOYEE',
  AGENCY_CUSTOMER: 'AGENCY_CUSTOMER',
  REFERRAL_CUSTOMER:'REFERRAL_CUSTOMER',
  PORTAL_SUB_ADMIN:'PORTAL_SUB_ADMIN',
  UNDERWRITING_ANALYST: 'UNDERWRITING_ANALYST',
  UNDERWRITING_MANAGER: 'UNDERWRITING_MANAGER',
  CATALOG_ANALYST: 'CATALOG_ANALYST',
  CATALOG_MANAGER: 'CATALOG_MANAGER',
  FINANCE_ANALYST: 'FINANCE_ANALYST',
  FINANCE_MANAGER: 'FINANCE_MANAGER'
};



export const fallbackRoute = {
  AGENCY_ADMIN: path.app.login,
  AGENCY_EMPLOYEE: path.quote.root,
  PORTAL_ADMIN: path.products.root,
  PORTAL_SUB_ADMIN: path.distributionList.list,
  UNDERWRITING_ANALYST: path.distributionList.list,
  UNDERWRITING_MANAGER: path.distributionList.list,
  FINANCE_ANALYST: path.policies.root,
  FINANCE_MANAGER: path.policies.root,
  CATALOG_ANALYST: path.catalog.industries,
  PORTAL_EMPLOYEE: path.products.root,
  MGA_ADMIN: path.products.root,
  MGA_EMPLOYEE: path.products.root,
  AGENCY_CUSTOMER: path.quote.root,
  REFERRAL_CUSTOMER: path.quote.root
  

};

export const superAdminAccessRoles = [
  AUTH_ROLES.PORTAL_ADMIN,
  AUTH_ROLES.PORTAL_EMPLOYEE,
  AUTH_ROLES.MGA_ADMIN,
  AUTH_ROLES.MGA_EMPLOYEE,
  AUTH_ROLES.AGENCY_CUSTOMER,
  AUTH_ROLES.REFERRAL_CUSTOMER
];

export const subAdminAccessRoles = [
  AUTH_ROLES.PORTAL_SUB_ADMIN
];
export const underwritingRoles = [
  AUTH_ROLES.UNDERWRITING_ANALYST,
  AUTH_ROLES.UNDERWRITING_MANAGER
];
export const catalogRoles = [
  AUTH_ROLES.CATALOG_ANALYST,
  AUTH_ROLES.CATALOG_MANAGER
];
export const financeRoles = [
  AUTH_ROLES.FINANCE_ANALYST,
  AUTH_ROLES.FINANCE_MANAGER
];

export const AgencyAdminAccessRoles = [AUTH_ROLES.AGENCY_ADMIN];

export const AgencyEmpAccessRoles = [AUTH_ROLES.AGENCY_EMPLOYEE];
