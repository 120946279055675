import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ITEM_HEIGHT = 48;

export function OptionMenu(props) {
  const { options, onClick, productId, color, bgColor } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget, productId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon color={color || "inherit" }/>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: !options.includes('icon') && '20ch',
            backgroundColor: bgColor,
            boxShadow: options.includes('icon') && '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${option.id}-${index}`}
            onClick={() => {
              onClick(option);
              handleClose();
            }}
          >
          {option.icon ?   
          <ListItemIcon>
            <img src={option.icon} alt={option.name} />
            </ListItemIcon> : null} 
            <Typography fontSize={option.icon && '.875rem'} color={option.icon && 'white'}>{option.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
