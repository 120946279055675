/* eslint-disable react/no-array-index-key */
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';

import { useAuth } from 'hooks/useAuth';
import { setApiData } from 'redux/modules/config';
import { AUTH_ROLES } from 'constant/authRole';
import { primaryColor } from 'theme';
import { path } from 'constant/pathLinksConstant';

const navLinkStyle = {
  borderRadius: '10px',
  '&:hover': { bgcolor: '#ECF4FF' },
  '&.Mui-selected': { bgcolor: '#ECF4FF' }
};

export const MultiLevel = ({ item, isDisabled, isMenuDisabled }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const isMatched = (url) => url.includes(pathname);

  const pathsToCheck = [
    path.catalog.managements,
    path.catalog.questions,
    path.catalog.industries,
    path.products.root,
    path.mgas.root
  ];
  
  useEffect(() => {
    if (pathsToCheck.includes(pathname)) {
      setOpen(true);
    }
  }, [pathname]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        disabled={isDisabled || isMenuDisabled}
        sx={{ ...navLinkStyle, bgcolor: open && '#ECF4FF' }}
      >
        <ListItemIcon sx={{ minWidth: '35px' }}>
          {open ? (
            <img src={item.iconActive} alt={item.title} />
          ) : (
            <img src={item.icon} alt={item.title} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize=".875rem"
              color={open ? '#0B1524' : '#4E5B6E'}
              fontWeight={500}
            >
              {item.title}
            </Typography>
          }
        />
        {open ? <ExpandLessIcon  /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding sx={{bgcolor:'#e7ebf057'}}>
          {children.map((child, key) => (
            <ListItemButton
              key={key}
              sx={{
                ...navLinkStyle,               
                 pl: 6,
                bgcolor: 'transparent'
              }}
              onClick={() => {
                setTimeout(() => {
                  if (
                    user.isParent ||
                    user.role.slug === AUTH_ROLES.PORTAL_ADMIN
                  ) {
                    navigate(child.link);
                    dispatch(setApiData({ limit: 25, offset: 0 }));
                  } else {
                    navigate({
                      pathname: child.link,
                      search: `?${createSearchParams({
                        companyId: user.company.companyId,
                        limit: 5,
                        offset: 0
                      })}`
                    });
                  }
                }, 250);
              }}
              selected={isMatched(child.link)}
              disabled={isDisabled || isMenuDisabled}
            >
        
              <ListItemText
                primary={
                  <Typography
                    fontSize=".875rem"
                    fontWeight={500}
                    color={isMatched(child.link) ? primaryColor : '#4E5B6E'}
                  >
                    {child.title}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
