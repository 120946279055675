import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

export function StatusChip({ color, customColor, label, borderRadius, fontSize, fontWeight, height, icon }) {
  const [statusColor, setstatusColor] = useState('#E6FFF3');
  const [labelColor, setLabelColor] = useState('#000000DE');

  const colorAttribute = (color !== 'custom') ? { color } : {};

  useEffect(() => {
    switch (color) {
      case 'success':
        setstatusColor('#E6FFF3');
        break;
      case 'warning':
        setstatusColor('#FFFAEB');
        break;
      case 'error':
        setstatusColor('#FFF3F3');
        break;
      case 'transparent':
        setstatusColor('#0000ffff');
        break;
      case 'custom':
        setstatusColor(customColor.bgHex);
        setLabelColor(customColor.colorHex);
        break;
      default:
        break;
    }
  }, [color]);

  return (
    <Chip
      sx={{
        textTransform: 'capitalize',
        background: statusColor,
        border: 'none',
        borderRadius: borderRadius || '5px',
        padding: '0',
        fontSize: fontSize || '',
        fontWeight: fontWeight || '400',
        height: height || '1.5rem',
        color: (color === 'custom') ? labelColor : ''
      }}
      variant="outlined"
      label={label}
      icon={icon}
      size="small"
      {...colorAttribute}
    />
  );
}
