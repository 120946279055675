export const QUOTE_STATUS_COLOR = {
    DATA_COLLECTION_BG : '#EFF9FC',
    DATA_COLLECTION_TEXT : '#1092BB',
    SELECT_INDICATION_BG : '#F8F0FF',
    SELECT_INDICATION_TEXT : '#734299',
    PREPARE_APPLICATION_BG : '#FFFBF1',
    PREPARE_APPLICATION_TEXT : '#AA7200',
    REVIEW_QUOTE_BG : '#FFF7F1',
    REVIEW_QUOTE_TEXT : '#BC5C17',
    BIND_QUOTE_BG : '#EBFFF7',
    BIND_QUOTE_TEXT : '#168D59',
    DEFAULT_BG : '#F5F6F7',
    DEFAULT_TEXT : '#4E5B6E',
    YELLOW_BG : 'yellow'
};

export const CARRIER_STATUS_COLOR = {
    SUCCESS_BG: '#ECFDF3',
    SUCCESS_TEXT: '#027A48',
    WARNING_BG: '#FFF7E8',
    WARNING_TEXT: '#D98C00',
    ERROR_BG: '#FFF3F3',
    ERROR_TEXT: '#F64B3A',
    DEFAULT_BG: '#F5F6F7',
    DEFAULT_TEXT: '#4E5B6E',
    YELLOW_TEXT: 'black'
};