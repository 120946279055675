/* eslint-disable no-nested-ternary */
import React from 'react';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';

export function ProgressBar({ showLabel, value }) {
    return (
        <>
            { !!showLabel && <Typography sx={{
                color: "#2C3543",
                fontSize: "0.8125rem",
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <span> Completion </span>
                <span> {value}% </span>
            </Typography> }

            <div style={{ display: 'block' }}>
            <LinearProgress
                variant="determinate"
                value={value}
                sx={{
                    '.MuiLinearProgress-bar': {
                        backgroundColor: value <= 30 ? '#D64550' :  value <= 49 ? '#D98C00' : '#2AC683',
                        borderRadius: '2px'
                    },
                    background: '#E6E7E8',
                    my: showLabel ? '1rem' : '0rem',
                    borderRadius: '2px',
                    width: showLabel ? '100%' : '80%',
                    display: showLabel ? 'inherit' : 'inline-block'
                }}
            />

            { !showLabel && 
                <span style={{ 
                    width: '20%',
                    display: 'inline-block',
                    textAlign: 'center',
                    verticalAlign: 'text-top',
                    color:'#2C3543',
                    fontWeight:500,
                    fontSize:'13px'
                }}> 
                    {value}% 
                </span>
            }
            </div>
        </>
    );
};