import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const applicationApi = createApi({
  reducerPath: 'applicationApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: (payload) => ({
        url: `/app-template/get-list?limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}&currentTab=${payload.currentTab}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    
    getApplicationsByProductId: builder.query({
      query: (payload) => {
        let url = `/dictionary-service/application-form/get-list?limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}`;

        if (payload.currentTab) {
          url += `&currentTab=${payload.currentTab}`;
        }

        if (payload.productId) {
          url += `&productId=${payload.productId}`;
        }

        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-API-KEY': API_KEY,
            Authorization: `Bearer ${payload.token}`
          }
        };
      }
    }),

    ApplicationDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/dictionary-service/application-form/get?applicationFormId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addApplication: builder.mutation({
      query: (payload) => ({
        url: '/app-template/addsss',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addApplicationProposal: builder.mutation({
      query: (payload) => ({
        url: '/application-proposal/add-application-proposals',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addCommonQuestion: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/application-form/add-form',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addIndicationForm: builder.mutation({
      query: (payload) => ({
        url: '/app-template/add-indication-form',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addFinalForm: builder.mutation({
      query: (payload) => ({
        url: '/app-template/add-final-form',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateCommonQuestion: builder.mutation({
      query: (payload) => ({
        url: '/dictionary-service/application-form/update-form',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    getApplicationProposal: builder.query({
      query: (payload) => ({
        url: `/application-proposal/get-application-proposals?applicationId=${payload.applicationId}`,
        method: 'GET',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getPaymentMethods: builder.mutation({
      query: (payload) => ({
        url: '/application/get-payment-method-options',
        method: 'POST',
        body: { applicationId: payload.applicationId },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    verfiyCarrierPaymentDetails: builder.mutation({
      query: (payload) => ({
        url: '/payment/verify-carrier-payment-details',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getCarrierPaymentPlans: builder.query({
      query: (payload) => ({
        url: `/payment/get-carrier-payment-plans?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCatalogIndustries: builder.query({
      query: (payload) => ({
        url: `/catalog-service/industries/get-industries?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    updateApplication: builder.mutation({
      query: (payload) => ({
        url: '/app-template/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    updateIndicationForm: builder.mutation({
      query: (payload) => ({
        url: '/app-template/update-indication-form',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateFinalForm: builder.mutation({
      query: (payload) => ({
        url: '/app-template/update-final-form',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddApplicationMutation,
  useGetApplicationsQuery,
  useApplicationDetailQuery,
  useUpdateApplicationMutation,
  useGetPaymentMethodsMutation,
  useGetCarrierPaymentPlansQuery,
  useVerfiyCarrierPaymentDetailsMutation,
  useGetApplicationsByProductIdQuery,
  useAddApplicationProposalMutation,
  useGetApplicationProposalQuery,
  useAddCommonQuestionMutation,
  useUpdateCommonQuestionMutation,
  useAddIndicationFormMutation,
  useUpdateIndicationFormMutation,
  useAddFinalFormMutation,
  useUpdateFinalFormMutation,
   useLazyGetCatalogIndustriesQuery
} = applicationApi;
