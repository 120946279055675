import { createSlice } from '@reduxjs/toolkit';

export const initialSnackbarState = { 
    open: false,
    snackbarData:{
        message: '',
        severity: 'success'
    }
 };

// Loading Redux Slice
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialSnackbarState,
  reducers: {
    setSnackbar(state, action) {
        
      return {
        ...state,
        open: action.payload.open,
        snackbarData: action.payload.snackbarData
      };
    }
  }
});

export const { setSnackbar } = snackbarSlice.actions;

export const selectSnackbarState = (state) => state.snackbar;
