import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';


export const agenenciesRoute = [
  {
    path: '',
    async lazy() {
      const  { Agencies } = await import("../pages/agencies/All/Agencies");
      return { Component: () => suspenseWrapper(<Agencies />) };
    }
  },
  {
    path: 'agents',
    async lazy() {
      const  { Agents } = await import("../pages/agencies/Agents/Agents");
      return { Component: () => suspenseWrapper(<Agents />) };
    }
  },
  {
    path: 'documents',
    async lazy() {
      const  { AgencyDocuments } = await import("../pages/agencies/Documents/Documents");
      return { Component: () => suspenseWrapper(<AgencyDocuments />) };
    }
  },
  {
    path: 'authorized-products',
    async lazy() {
      const  { AuthorizedProductsPage } = await import("../pages/products/AuthorizedProducts/AuthorizedProducts");
      return { Component: () => suspenseWrapper(<AuthorizedProductsPage />) };
    }
  },
  {
    path: 'authorized-agencies',
    async lazy() {
      const  { AuthorizedAgenciesPage } = await import("../pages/products/AuthorizedAgencies/AuthorizedAgencies");
      return { Component: () => suspenseWrapper(<AuthorizedAgenciesPage />) };
    }
  },
  {
    path: 'commissions',
    async lazy() {
      const  { Commissions } = await import("../pages/products/Commissions/Commissions");
      return { Component: () => suspenseWrapper(<Commissions />) };
    }
  }
];
