import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const policyApi = createApi({
  reducerPath: 'policyApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getPolicyList: builder.query({
      query: (payload) => ({
        url: `/policy/list?limit=${payload.limit}&offset=${payload.offset}&effectiveDate=${payload.effectiveDate}&expirationDate=${payload.expirationDate}&productId=${payload.productId}&mgaCompanyId=${payload.mgaCompanyId}&agencyId=${payload.agencyId}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getPolicyDetails: builder.query({
      query: (payload) => ({
        url: `/policy/get-single-policy?policyId=${payload.policyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPaymentDetails: builder.query({
      query: (payload) => ({
        url: `/payment/get-single-payment-transaction-v2?paymentTransactionId=${payload.paymentTransactionId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPolicyPaymentList: builder.query({
      query: (payload) => ({
        url: `/payment/get-distribution-payment-list-v2?policyId=${payload.policyId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    
    getPolicyBySearch: builder.query({
      query: (payload) => ({
        url: `/policy/search-policy-by-number?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getTransactionTypes: builder.query({
      query: (payload) => ({
        url: `/payment/transaction-types?billingType=${payload.billingType}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addPolicy: builder.mutation({
      query: (payload) => ({
        url: '/policy/create',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addPolicyPayment: builder.mutation({
      query: (payload) => ({
        url: '/payment/add-new-payment-transaction-v2',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    createCompany: builder.mutation({
      query: (payload) => ({
        url: '/company/create-customer-company',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    createTransactionTemplate: builder.mutation({
      query: (payload) => ({
        url: '/payment/create-transaction-template',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updatePolicy: builder.mutation({
      query: (payload) => ({
        url: '/policy/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    deletePayment: builder.mutation({
      query: (payload) => ({
        url:'payment/delete',
        body: payload.data,
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useAddPolicyMutation,
  useGetPolicyListQuery,
  useGetPolicyDetailsQuery,
  useUpdatePolicyMutation,
  useLazyGetPolicyBySearchQuery,
  useAddPolicyPaymentMutation,
  useGetPolicyPaymentListQuery,
  useGetPaymentDetailsQuery,
  useGetTransactionTypesQuery,
  useCreateTransactionTemplateMutation,
  useDeletePaymentMutation,
  useCreateCompanyMutation
} = policyApi;
