import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (payload) => ({
        url: `/products/product-list?limit=${
          payload.limit === -1 ? 200 : payload.limit
        }&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    productDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/products/product-detail?productId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addProduct: builder.mutation({
      query: (payload) => ({
        url: '/products/add',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateProduct: builder.mutation({
      query: (payload) => ({
        url: '/products/product-update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddProductMutation,
  useGetProductsQuery,
  useProductDetailQuery,
  useUpdateProductMutation
} = productApi;
