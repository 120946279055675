export const path = {
  app: {
    login: '/login',
    signUp: '/Sign-up',
    signUpSuccess: '/sign-up-success',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    thankYou: '/thank-you',
    agentSignup: '/agent-signup',
    verifyUserToken: '/verify-user-token',
    verifyConsumerCustomer: '/verify-consumer-customer',
    verifyCustomer: '/verify-customer',
    verifyAdmin: '/verify-admin',
    verifyNewAgency: '/verify-new-agency'
  },
  products: {
    root: '/products',
    allApplications: '/products/applications/all',
    applications: '/products/product-applications',
    carrierApplications: '/products/carrier-applications',
    indicationApplication: '/products/indication-application',
    quoteApplication: '/products/quote-application'
  },
  agencies: {
    root: '/agencies',
    agents: '/agencies/agents',
    documents: '/agencies/documents',
    authAgencies: '/agencies/authorized-agencies',
    commissions: '/agencies/commissions',
    authProducts: '/agencies/authorized-products'
  },
  mgas: {
    root: '/mgas',
    rating: '/mgas/rating-systems',
    mgaUsers: '/mgas/mga-users',
    authorizedCarrier: '/mgas/authorized-carriers',
    authMgas: '/mgas/authorized-mga',
    authProducts: '/mgas/authorized-products'
  },
  rating: {
    newRating: '/mgas/rating/create',
    updateRating: '/mgas/rating/edit'
  },
  carriers: { root: '/carriers' },
  configuration: { root: '/configuration' },
  distribution: {
    root: '/distribution',
    binding: '/distribution/binding',
    policy: '/distribution/policy',
    mgaSubmission: '/distribution/mga-submission',
    payment: '/distribution/payment',
    applicationForms: '/distribution/application-forms',
    quoteIndication: '/distribution/quote-indication',
    dataCollection: '/distribution-list/data-collection',
    addQuestions: '/distribution-list/data-collection/add-questions',
    summary: '/distribution-list/summary',
    indicationRequest: '/distribution-list/indication-request',
    indications: '/distribution-list/indications',
    quoteRequest: '/distribution-list/quote-request',
    reviewQuotes: '/distribution-list/review-quotes',
    payments: '/distribution-list/payments',
    policies: '/distribution-list/policies',
    list: '/distribution-list'
  },
  distributionList: {
    root: '/distribution',
    binding: '/distribution/binding',
    policy: '/distribution/policy',
    mgaSubmission: '/distribution/mga-submission',
    payment: '/distribution/payment',
    applicationForms: '/distribution/application-forms',
    quoteIndication: '/distribution/quote-indication',
    dataCollection: '/distribution/data-collection',
    indicationRequest: '/distribution/indication-request',
    indications: '/distribution/indications',
    quoteRequest: '/distribution/quote-request',
    reviewQuotes: '/distribution/review-quotes',
    list: '/distribution-list'
  },
  serviceRequests: { root: '/service-requests', add: '/service-requests/add' },
  payments: { root: '/payments' },
  reports: { root: '/reports' },
  catalog: {
    root: '/catalog',
    managements: '/managements',
    questions: '/questions',
    industries: '/industries',
    appetite: '/appetite'
  },
  manageApplications: {
    root: '/manage-applications',
    dictonary: '/manage-applications/dictonary',
    applications: '/manage-applications/applications',
    newApplication: '/manage-applications/applications/create',
    editApplication: '/manage-applications/applications/edit',
    changeHistoryLogs: '/change-hostory-logs'
  },
  policies: { root: '/policies' },
  documentMarker: { root: '/document-marker' },
  users: { root: '/users' },
  applications: {
    root: '/application-list',
    create: '/applications/create',
    edit: '/applications/edit',
    verify: '/verify-application'
  },
  quote: { root: '/quote' },
  agents: { root: '/agents' },
  network: { root: '/network' },
  documentTemplate: {
    root: '/document-template',
    proposals: '/document-template/proposals',
    tagTemplate:'/document-template/tag-template'
  },
  adminAddQuote: { root: '/applications/create' }
};
