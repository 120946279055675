export const whiteColor = '#FFFFFF';
export const blackColor = '#0B1524';

export const lightGreyColor = '#F5F6F7';
export const darkGreyColor = '#5B616B';



export const lightGrey50 = '#FBFBFB';
export const lightGrey100 = '#F6F7F8';
export const lightGrey200 = '#757B86';
export const lightGrey300 = '#9097A1';
export const lightGrey400 = '#B2B2B2';
export const lightGrey500 = "#C4CAD3";
export const darkGrey50 = '#7278820f';
export const darkGreyColor50 = '#4E5B6E';
export const darkGreyColor100 = '#727882';

export const headingColor = '#39404B';
export const mediumGreyColor = '#00000030';
export const slateBlueColor = '#606979';

export const lightBlue = '#F2F7FF';
export const lightBlue50 = '#ECF4FF';
export const lightBlue100 = "#F9FAFB";
export const lightBlue200 = "#F2F8FF";
export const lightBlue300 = "#F8F9FA";
export const lightBlueColor = '#E4F5FCB3';
export const lightBlueColor100 = '#AED1FF';
export const lightBlueColor200 = "#F3FBFE";
export const skyBlueColor = '#18AEE21A';

export const blueColor = '#2574DA';
export const blueColor100 = '#3487f366';
export const violetColor = '#25315B';

export const primaryColor = '#3487F3';
export const primaryHoverColor = '#2D2975';

export const secondaryHoverColor = '#7D828B';
export const bgColor = "#F6F7F8";
export const stepperBorderColor = '#eaeaf0';
export const buttonBorderColor = "#D4D8DD";
export const subheadingColor = "#3F454E";
export const warningColor = "#CF7F34";
export const warningColor50 = '#FAC48B';

export const checkedColor = "#E1E6ED";
export const boxShadowColor = "#00000019";
export const secondaryColor = '#ffffff';

export const successColor50 = '#CEEBDF';
export const successBackgroundColor = '#EFFFF8';
export const successColor = '#1E9D66';
export const productHeadingColor = "#262D39";
export const carrierheadingColor = "#9097A1";
export const disabledButtonColor = "#aecffa";
export const borderRightColor = "#E2E5E9";
export const carrierNameColor = "#6B727D";
export const infoColor = "#3C5779";
export const redColor = "#F63A60";
export const successBgColor100 = "#D9FFEA";
export const successBorderColor = "#C2EAD4";
export const quoteBorderColor = "#ECEFF2";

export const lightRed50 = "#eec2c4";
export const lightRed100 = "#ffeaeb";
export const lightRed200 = "#EEDEC2";
export const lightRed300 = "#FFF5EA";
export const darkRed = "#880000";

export const darkOrange = "#885700";