import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getQuestionsList: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-question/list?limit=${payload.limit}&offset=${payload.offset}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getIndustryHierechy: builder.query({
      query: (payload) => ({
        url: `/catalog-service/industries/get-industries-with-hierechy?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getQuestionDetails: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-question/get-details?catalogQuestionId=${payload.catalogQuestionId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductBySearch: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-product/products-search?limit=${payload.limit}&offset=${payload.offset}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCarrierBySearch: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-company/company-search?limit=${payload.limit}&searchBy=${payload.searchBy}&businessType=${payload.businessType}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getProductList: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-product/list-industry-product-carrier?industryId=${payload.industryId}&offset=${payload.offset}&limit=${payload.limit}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getStateList: builder.query({
      query: (payload) => ({
        url: `/locales/get-states?countryCode=us`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuestionList: builder.query({
      query: (payload) => ({
        url: `catalog-service/catalog-management/question-mapping/list-question-mapping?industryId=${payload.industryId}&offset=${payload.offset}&limit=${payload.limit}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getQuestionRuleList: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/question-rule/list?industryId=${payload.industryId}&offset=${payload.offset}&limit=${payload.limit}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSearchedQuestion: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-question/list?limit=${payload.limit}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSingleIndustry: builder.query({
      query: (payload) => ({
        url: `/catalog-service/industries/get-single-industry?industryId=${payload.industryId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getRuleDetails: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/question-rule/get-rule-details?questionMappingRulesId=${payload.questionMappingRulesId}&catalogIndustryProductId=${payload.catalogIndustryProductId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getConfigDataDetails: builder.query({
      query: (payload) => ({
        url: `dictionary-service/application-form/get?applicationFormId=${payload.applicationFormId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getRiskTypes: builder.query({
      query: (payload) => ({
        url: `/catalog-service/industries/get-risk-types?naicsCodes=${payload.naicsCodes}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCarrierProducts: builder.query({
      query: (payload) => ({
        url: `/catalog-service/appetite/carrier-products/list?carrierId=${payload.carrierId}&productId=${payload.productId}&offset=${payload.offset}&limit=${payload.limit}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCarrierProductConfig: builder.query({
      query: (payload) => ({
        url: `/catalog-service/appetite/carrier-products/configuration?catalogCarrierProductId=${payload.catalogCarrierProductId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getRuleList: builder.query({
      query: (payload) => ({
        url: `/catalog-service/appetite/rule/list?catalogCarrierProductId=${payload.catalogCarrierProductId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSingleRule: builder.query({
      query: (payload) => ({
        url: `/catalog-service/appetite/rule/single-rule?catalogCarrierProductId=${payload.catalogCarrierProductId}&catalogAppetiteRulesId=${payload.catalogAppetiteRulesId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getSearchFieldsForCatalog: builder.query({
      query: (payload) => ({
        url: `/dictionary-service/manage-applications/dictionary/search-fields-for-catalog?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    exportQuestionData: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/question-rule/get-question-mapping-rules-data?carrierId=${payload.carrierId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        },
        responseType: 'blob'
      }),
      transformResponse: (response) => response.result
    }),
    exportIndustryData: builder.query({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/question-rule/get-carrier-availability-data?carrierId=${payload.carrierId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        },
        responseType: 'blob'
      })
    }),

    addQuestions: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-question/add',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addCarrierProductConfig: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/carrier-products/create-configuration',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateCarrierProductConfig: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/carrier-products/update-configuration',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    addCarrierProducts: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/carrier-products/create',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateIndustry: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/industries/update-single-industry',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateCarrier: builder.mutation({
      query: (payload) => ({
        url: 'catalog-service/catalog-product/update-assigned-product-carrier',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    addRule: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-management/question-rule/add-rule',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addAppetiteRule: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/rule/create',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateAppetiteRule: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/appetite/rule/update',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateFormConfig: builder.mutation({
      query: (payload) => ({
        url: 'dictionary-service/application-form/update-form-config',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateRule: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-management/question-rule/update-rule',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    addQuestionMapping: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-management/question-mapping/add-question-mapping',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    addMultiCarrier: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-product/assign-product',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    bulkAddQuestion: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/migration/new-bulk-add-question-rules',
        method: 'POST',
        body: payload.data,
        headers: {
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    bulkAddIndustry: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/migration//new-bulk-add-industry-product-carrier',
        method: 'POST',
        body: payload.data,
        headers: {
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateQuestion: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-question/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    changeInheritence: builder.mutation({
      query: (payload) => ({
        url: '/catalog-service/catalog-product/change-carrier-inheritence',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    changeQuestionInheritence: builder.mutation({
      query: (payload) => ({
        url: 'catalog-service/catalog-management/question-mapping/update-inheritance',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    deleteQuestion: builder.mutation({
      query: (payload) => ({
        url: `/catalog-service/catalog-question/delete?catalogQuestionId=${payload.catalogQuestionId}`,
        method: 'DELETE',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Delete']
    }),
    deleteProduct: builder.mutation({
      query: (payload) => ({
        url: `catalog-service/catalog-product/unassign-product?industryId=${payload.industryId}&productId=${payload.productId}`,
        method: 'DELETE',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Delete']
    }),
    deleteProductCarrier: builder.mutation({
      query: (payload) => ({
        url: `/catalog-service/catalog-product/delete-catalog-industry-product?carrierId=${payload.carrierId}&industryId=${payload.industryId}&productId=${payload.productId}`,
        method: 'DELETE',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Delete']
    }),
    deleteRule: builder.mutation({
      query: (payload) => ({
        url: `/catalog-service/catalog-management/question-rule/delete-rule?questionMappingRulesId=${payload.questionMappingRulesId}`,
        method: 'DELETE',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    deleteAppetiteRule: builder.mutation({
      query: (payload) => ({
        url: `/catalog-service/appetite/rule/delete`,
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    })
  })
});

export const {
  useAddQuestionsMutation,
  useGetQuestionsListQuery,
  useUpdateQuestionMutation,
  useGetQuestionDetailsQuery,
  useDeleteQuestionMutation,
  useLazyGetIndustryHierechyQuery,
  useLazyGetProductBySearchQuery,
  useGetProductListQuery,
  useLazyGetCarrierBySearchQuery,
  useLazyGetStateListQuery,
  useAddMultiCarrierMutation,
  useGetQuestionListQuery,
  useLazyGetSearchedQuestionQuery,
  useAddQuestionMappingMutation,
  useGetQuestionRuleListQuery,
  useAddRuleMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation,
  useChangeInheritenceMutation,
  useGetSingleIndustryQuery,
  useUpdateIndustryMutation,
  useGetRuleDetailsQuery,
  useChangeQuestionInheritenceMutation,
  useUpdateCarrierMutation,
  useDeleteProductCarrierMutation,
  useLazyGetRiskTypesQuery,
  useUpdateFormConfigMutation,
  useGetConfigDataDetailsQuery,
  useBulkAddQuestionMutation,
  useBulkAddIndustryMutation,
  useLazyExportQuestionDataQuery,
  useLazyExportIndustryDataQuery,
  useDeleteProductMutation,
  useGetCarrierProductsQuery,
  useAddCarrierProductsMutation,
  useGetCarrierProductConfigQuery,
  useAddCarrierProductConfigMutation,
  useUpdateCarrierProductConfigMutation,
  useGetRuleListQuery,
  useGetSingleRuleQuery,
  useLazyGetSearchFieldsForCatalogQuery,
  useAddAppetiteRuleMutation,
  useUpdateAppetiteRuleMutation,
  useDeleteAppetiteRuleMutation
} = catalogApi;
