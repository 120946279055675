export const downloadJsonHandler = (json, fileName) => {
    // Check if json is already a string
    const jsonString = typeof json === 'string' ? json : JSON.stringify(json, null, 2);
  
    const blob = new Blob([jsonString], { type: 'application/json' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  