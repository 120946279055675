/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  TableFooter,
  Tooltip,
  Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useGetUniqueKey } from 'hooks/useGetUniqueKey';
import TableAccordion from 'components/TableAccordian';
import FetchingLaoder from 'components/Loader/FetchingLoader';
import { ProgressBar } from 'components/ProgressBar';
import { downloadJsonHandler } from 'utils/jsonDownloader';

import downloadIcon from '../../assets/icons/download-blue.svg';
import { CustomTableToolbar } from './CustomTableToolbar';
import { CustomPaginationActionsTable } from './CustomTablePagination';


export const CustomTable = React.memo(
  ({
    header,
    rows,
    totalRecords,
    onPageChange,
    onActionBtnClick,
    hideToolBox,
    hidePagination,
    noDataMessage,
    showSearchAndFilter,
    handleSearch,
    dataType,
    disableAddBtn,
    expandableRow,
    noPadding,
    headerBg,
    showNumberPagination,
    isFetchingData,
    padding,
    height,
    stickyHeader,
    headerColor,
    headerPadding,
    hideShowTotalNumber,
    fontSize,
    minWidth 
  }) => {
    const getUniqueKey = useGetUniqueKey();
    const { pathname } = useLocation();

    const showTopbar = pathname.includes('distribution-list');

    const renderTableCell = (tableHead, row, index) => {
      let cell;
      switch (dataType) {
        case 'configurationData':
          cell = (
            <TableCell
              sx={{
                overflowWrap: 'anywhere',
                minWidth: '10rem',
                whiteSpace: 'pre-wrap'
              }}
              key={getUniqueKey({ data: row[tableHead.id], index })}
              align={tableHead.align ? tableHead.align : 'left'}
            >
              {row[tableHead.id]}
            </TableCell>
          );
          break;
        case 'data-collection':
          cell = (
            <TableCell
              key={getUniqueKey({ data: 'row[tableHead.id]', index })}
              align={tableHead.align ? tableHead.align : 'left'}
              sx={{ padding: '1rem !important' }}
            >
              {(() => {
                switch (true) {
                  case index === 4:
                    return (
                      <ProgressBar
                        value={row !== undefined && row[tableHead.id]}
                      />
                    );
                  case tableHead?.id === 'products' &&
                    Array.isArray(row[tableHead.id]):
                    return (
                      <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                        <span
                          style={{
                            fontSize: '0.875rem',
                            maxWidth: '10rem',
                            fontWeight: '500',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            flex: '0 1 auto',
                            flexGrow: 1
                          }}
                        >
                          {row[tableHead.id][0]?.productName}
                        </span>
                        {row[tableHead.id]?.length > 1 && (
                          <Tooltip
                            title={
                              row[tableHead.id]?.length ? (
                                <List dense sx={{ padding: 0 }}>
                                  {row[tableHead.id]?.map((item) => (
                                    <ListItem
                                      key={item.productName}
                                      sx={{ paddingY: 0 }}
                                      disableGutters
                                    >
                                      {item.productName}
                                    </ListItem>
                                  ))}
                                </List>
                              ) : (
                                <CircularProgress
                                  color="secondary"
                                  fourColor={false}
                                  variant="indeterminate"
                                  size={20}
                                />
                              )
                            }
                          >
                            <span
                              style={{
                                color: '#3487F3',
                                borderRadius: '2.3125rem',
                                background: '#ECF4FF',
                                padding: '0rem 0.5rem',
                                alignSelf: 'flex-end'
                              }}
                            >
                              +{row[tableHead.id].length - 1}
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    );
                  case tableHead?.id === 'carriers' &&
                    Array.isArray(row[tableHead.id]):
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          cursor: 'pointer'
                        }}
                      >
                        <span
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            color: '#4E5B6E'
                          }}
                        >
                          {row[tableHead.id][0]?.carrierName}
                        </span>
                        {row[tableHead.id]?.length > 1 && (
                          <Tooltip
                            title={
                              row[tableHead.id]?.length ? (
                                <List dense sx={{ padding: 0 }}>
                                  {row[tableHead.id]?.map((item) => (
                                    <ListItem
                                      key={item.carrierName}
                                      sx={{ paddingY: 0 }}
                                      disableGutters
                                    >
                                      {item.carrierName}
                                    </ListItem>
                                  ))}
                                </List>
                              ) : (
                                <CircularProgress
                                  color="secondary"
                                  fourColor={false}
                                  variant="indeterminate"
                                  size={20}
                                />
                              )
                            }
                          >
                            <span
                              style={{
                                color: '#3487F3',
                                borderRadius: '2.3125rem',
                                background: '#ECF4FF',
                                padding: '0rem 0.5rem',
                                alignSelf: 'flex-end'
                              }}
                            >
                              +{row[tableHead.id].length - 1}
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    );
                  case tableHead?.id === 'srProductData':
                    return (
                      <button
                        type="button"
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          margin: 0
                        }}
                        onClick={() =>
                          downloadJsonHandler(
                            row[tableHead.id],
                            'jsonData.json'
                          )
                        }
                      >
                        <img src={downloadIcon} alt="downloadIcon" />
                      </button>
                    );
                  default:
                    return (
                      <Typography
                        fontSize="14px"
                        color="#4E5B6E"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {row[tableHead.id]}
                      </Typography>
                    );
                }
              })()}
            </TableCell>
          );
          break;
        default:
          cell = (
            <TableCell
              key={getUniqueKey({ data: row[tableHead.id], index })}
              align={tableHead.align ? tableHead.align : 'left'}
              sx={{ padding: padding && '1rem !important' }}
            >
              {row[tableHead.id]}
            </TableCell>
          );
          break;
      }

      return cell;
    };

    const [expandedRow, setExpandedRow] = React.useState(null);

    React.useEffect(() => {
      if (isFetchingData) setExpandedRow(null);
    }, [isFetchingData]);

    const handleExpandRow = (rowIndex) => {
      setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
    };

    const renderTableBody = () => (
      <TableBody>
        {rows.map((row, rowIndex) =>
          expandableRow ? (
            <TableAccordion
              key={getUniqueKey({ row, rowIndex })}
              header={header}
              row={row}
              isExpanded={expandedRow === rowIndex}
              onToggleExpand={() => handleExpandRow(rowIndex)}
            />
          ) : (
            <TableRow key={getUniqueKey({ row, rowIndex })}>
              {header.map((tableHead, index) =>
                renderTableCell(tableHead, row, index, rowIndex)
              )}
            </TableRow>
          )
        )}
      </TableBody>
    );

    const noDataBody = () => (
      <TableBody>
        <TableRow>
          <TableCell
            colSpan={header.length + 1}
            style={{
              textAlign: 'center',
              fontWeight: '700'
            }}
          >
            {noDataMessage}
          </TableCell>
        </TableRow>
      </TableBody>
    );

    return (
      <Paper
        sx={{ mb: 2 }}
      >
        {!hidePagination && !expandableRow && !showTopbar && !hideShowTotalNumber && (
          <CustomTableToolbar
            onActionBtnClick={onActionBtnClick}
            totalRecords={totalRecords}
            visibleRecords={rows?.length}
            hideToolBox={hideToolBox}
            showSearchAndFilter={showSearchAndFilter}
            handleSearch={handleSearch}
            disableAddBtn={disableAddBtn}
          />
        )}

        <TableContainer
          sx={{
            padding:
              expandableRow || noPadding ||
              dataType === 'data-collection' ||
              dataType === 'indication-request'
                ? '0px'
                : hidePagination
                ? '20px'
                : '0px 20px',
            maxHeight: height || '100%',
            overflow: !stickyHeader && 'initial' ,
            border:'1px solid #EAECF0'
            
          }}
        >
          <Table
            sx={{ minWidth : minWidth || 650, mb: rows?.length ? 0 : 4 }}
            aria-label="table"
            stickyHeader
          >
            <TableHead>
              <TableRow
                sx={{
                  background: headerBg || '#F5F6F8',
                  borderRadius: '14px',
                  th: { fontWeight: 'bold' },
                  minWidth: '5rem',
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {header.map((head, index) => (
                  <TableCell
                    style={{
                      color:headerColor || '#6B727D',
                      backgroundColor:headerBg || '#F5F6F8',
                      padding:headerPadding || 'auto',
                      fontSize: fontSize || '14px',
                      fontWeight: '500',
                      borderRadius:
                        expandableRow && index === 0 ? '0.25rem 0 0 0' : '0'
                    }}
                    key={getUniqueKey({ head })}
                  >
                    {head.label}
                  </TableCell>
                ))}

                {expandableRow && (
                  <TableCell style={{ borderRadius: '0 0.25rem 0 0', backgroundColor:'#F5F6F8' }} />
                )}
              </TableRow>
            </TableHead>

            {isFetchingData ? (
              <TableBody>
                <TableRow >
                  <TableCell
                    colSpan={header.length + 1}
                    className="expandRowCell"
                    
                  >
                    <FetchingLaoder />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : rows?.length ? (
              renderTableBody()
            ) : (
              noDataBody()
            )}

            <TableFooter>
              <TableRow sx={{ display: hidePagination && 'none' }}>
                {onPageChange && (
                  <CustomPaginationActionsTable
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                    dataType={dataType}
                    showNumberPagination={showNumberPagination}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
);
