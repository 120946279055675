export const API_KEY = 'bac87873a53869e58c0622ac74e9015046084d4f';
export const API_BASE_URL = 'https://dev-api.squarerisk.net/api/v2/';
export const SOCKET_BASE_URL = 'wss://dev-api.squarerisk.net';
export const SOCKET_SITE_PATH = '/api/v2/socket';
export const S3_BUCKET_NAME = 'square-risk-uat-us-east-files';
export const S3_BUCKET_REGION = 'us-east-1';
export const S3_BUCKET_COMPANY_LOGO_PATH = 'company/logo';
export const S3_BUCKET_COMPANY_FILE_PATH = 'company-product/policies';
export const S3_BUCKET_COMPAPY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_COMPANY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_FILE = 'pre-signature-templates';
export const S3_BUCKET_ACCESS_KEY = 'AKIA3OKR7TQRDGHCK3CO';
export const S3_BUCKET_SECRET_ACCESS_KEY =
  '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';

export const STRIPE_API_KEY = 'pk_test_51MAO5UKMdKiO05uQjn0G6jaJ6S4XpbpCCAZ6OnQ27e6A4qwpxRpZHvf3mYauIe5P7wG1dbwG53Y4Y9bydRxL3WL700mMM74HOL';
export const AGENT_PANEL_BASE_URL = 'https://dev.squarerisk.net';
