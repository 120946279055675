import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Paper } from '@mui/material';

import { CustomCard } from './CustomCard';

export function CardContainer({ distributionSteps, cardData }) {

  return (
    <Grid
      container
      spacing={1}
      sx={{
        padding: '1rem',
        boxShadow: '0px 4px 12px 0px rgba(11, 21, 36, 0.15)',
        margin: '0',
        width: '100%'
      }}
    >
      {Object.keys(distributionSteps).map((label, index) => (
        <Grid
          item
          xs={3}
          key={label}
          sx={{
            paddingLeft: index === 0 ? '0 !important' : 'inherit',
            paddingTop: '0 !important'
          }}
        >
          <Paper
            sx={{
              background: '#F6F8F9',
              borderRadius: '0.25rem 0.25rem 0 0',
              padding: '0.75rem',
              height: '100%',
              maxHeight: '15.5rem',
              overflowY: 'auto',
              boxShadow: 'none'
            }}
          >
            <Typography
              sx={{
                fontSize: '0.75rem',
                fontWeight: 600,
                color: '#5B616B',
                letterSpacing: '0.0045rem',
                marginBottom: '0.75rem',
                textTransform: 'uppercase'
              }}
            >
              {distributionSteps[label]}
            </Typography>

            {cardData?.screen === distributionSteps[label] && (
              <CustomCard
                cardData={cardData}
                distributionSteps={distributionSteps}
              />
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
