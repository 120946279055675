import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: (payload) => ({
        url: payload.companyId
          ? `/company/company-list?businessType=${
              payload.businessType
            }&companyId=${payload.companyId}&limit=${
              payload.limit === -1 ? 200 : payload.limit
            }&offset=${payload.offset}&searchBy=${payload.searchBy}`
          : `/company/company-list?businessType=${payload.businessType}&limit=${
              payload.limit === -1 ? 200 : payload.limit
            }&offset=${payload.offset}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),


    getAgencies: builder.query({
      query: (payload) => ({
        url: payload.companyId
          ? `/company/agency-list?businessType=${
              payload.businessType
            }&companyId=${payload.companyId}&limit=${
              payload.limit === -1 ? 200 : payload.limit
            }&offset=${payload.offset}`
          : `/company/agency-list?businessType=${payload.businessType}&limit=${
              payload.limit === -1 ? 200 : payload.limit
            }&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    getCarriers: builder.query({
      query: (payload) => ({
        url: `/company/get-companies-by-type?businessType=${payload.businessType}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),
    getCompanyByType: builder.query({
      query: (payload) => ({
        url: `/company/get-companies-by-type-payment?businessType=${payload.businessType}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    companyDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-company-detail?companyId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getCarrierList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/carrier-list?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgencyDetail: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-agency-detail?companyId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    addCompany: builder.mutation({
      query: (payload) => ({
        url: '/company/add-agency',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    addMga: builder.mutation({
      query: (payload) => ({
        url: '/company/add-company',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    getCompanyDocuments: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-document/company-document?companyId=${payload.companyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
      // transformResponse: (response) => response,
    }),
    getDocumentDetails: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-document/single-company-document?companyDocumentId=${payload.companyDocumentId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgencyCatalogSettings: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/agency-catalog-settings?agencyId=${payload.agencyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    uploadBindDocuments: builder.mutation({
      query: (payload) => ({
        url: '/company-document/upload-bind-documents',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateBindDocuments: builder.mutation({
      query: (payload) => ({
        url: '/company-document/update-company-document',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),

    updateCompany: builder.mutation({
      query: (payload) => ({
        url: '/company/update-agency',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    updateMga: builder.mutation({
      query: (payload) => ({
        url: '/company/update-company',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),

    addCarrier: builder.mutation({
      query: (payload) => ({
        url: '/company/add-carrier',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateCarrier: builder.mutation({
      query: (payload) => ({
        url: '/company/update-carrier',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Put']
    }),
    updateCarrierCatalog: builder.mutation({
      query: (payload) => ({
        url: '/company/update-agency-catalog-settings',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    authorzisedCarrier: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-authorize-carrier-company?companyId=${payload.companyId}&sortBy=${payload.sortBy}&limit=${payload.limit}&order=${payload.order}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response
    }),
    addAuthorizedCarrier: builder.mutation({
      query: (payload) => ({
        url: '/company-relationship/authorize-carrier-company',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getAuthorzisedCarrier: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-single-authorized-company?companyRelationshipId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    updateAuthorizedCarrier: builder.mutation({
      query: (payload) => ({
        url: '/company-relationship/update-single-authorized-company',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getMgaTaskList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-mga-task-list?mgaCompanyId=${payload.companyId}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response
    }),
    addMgaTask: builder.mutation({
      query: (payload) => ({
        url: '/company-relationship/add-mga-task',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updateSingleMgaTask: builder.mutation({
      query: (payload) => ({
        url: '/company-relationship/update-single-mga-task',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    getSingleMgaTaskList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-single-mga-task?carrierTaskId=${payload.id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getBindCompanyDocuments: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-document/get-bind-company-documents?applicationId=${payload.applicationId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getAthorizedProductsCarriers: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-authorized-products-carriers?companyId=${payload.companyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getAgencyList: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: '/company/agency-list-lite',
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgencyListBySearch: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-agency-list?searchBy=${payload.data.searchBy}&limit=${payload.data.limit}&offset=${payload.data.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAccountListBySearch: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-agency-customer-list?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    getMgaCarrier: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-agency-mga-carrier?limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}&mgaCompanyId=${payload.mgaCompanyId}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    
    getCarrierListBySearch: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-agency-carriers?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getMgaListBySearch: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company-relationship/get-agency-mgas?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}&agencyId=${payload.agencyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),

    verfiyAgencyToken: builder.mutation({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: '/onboarding/verify-agency-token',
        method: 'POST',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),

    getAgenciesForDistribution: builder.query({
      keepUnusedDataFor: 1,
      query: (payload) => ({
        url: `/company/get-agency-list?searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAgents: builder.query({
      query: (payload) => ({
        url: `/company/agents-list?agencyId=${payload.agencyId}&searchBy=${payload.searchBy}&limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPayerReceivers: builder.query({
      query: (payload) => ({
        url: `/company/get-companies-by-type-payment?searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getAdminToken: builder.query({
      query: (payload) => ({
        url: `/user/generate-admin-token`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    })
  })
});

export const {
  useAddCompanyMutation,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useCompanyDetailQuery,
  useUpdateCompanyMutation,
  useAddCarrierMutation,
  useUpdateCarrierMutation,
  useAuthorzisedCarrierQuery,
  useAddAuthorizedCarrierMutation,
  useGetAuthorzisedCarrierQuery,
  useUpdateAuthorizedCarrierMutation,
  useGetMgaTaskListQuery,
  useAddMgaTaskMutation,
  useUpdateSingleMgaTaskMutation,
  useGetSingleMgaTaskListQuery,
  useUploadBindDocumentsMutation,
  useGetBindCompanyDocumentsQuery,
  useUpdateBindDocumentsMutation,
  useGetCompanyDocumentsQuery,
  useGetDocumentDetailsQuery,
  useGetAthorizedProductsCarriersQuery,
  useVerfiyAgencyTokenMutation,
  useGetAgencyListQuery,
  useAddMgaMutation,
  useUpdateMgaMutation,
  useGetAgencyDetailQuery,
  useGetAgenciesQuery,
  useGetAgenciesForDistributionQuery,
  useLazyGetAdminTokenQuery,
  useLazyGetAgencyListBySearchQuery,
  useLazyGetAccountListBySearchQuery,
  useLazyGetCarrierListBySearchQuery,
  useLazyGetMgaListBySearchQuery,
  useLazyGetAgentsQuery,
  useLazyGetCarriersQuery,
  useGetCarrierListBySearchQuery,
  useLazyGetPayerReceiversQuery,
  useLazyGetMgaCarrierQuery,
  useLazyGetCompanyByTypeQuery,
  useUpdateCarrierCatalogMutation,
  useGetAgencyCatalogSettingsQuery,
  useLazyGetCarrierListQuery
  
} = companyApi;
