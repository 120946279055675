/* eslint-disable import/no-extraneous-dependencies */
import {
  combineReducers,
  configureStore,
  createListenerMiddleware
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
// Redux slices
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import { modalSlice, loaderSlice, currentEditorSlice, snackbarSlice  } from './modules';
// Redux API Queries/Mutations
import { configApi, configSlice } from './modules/config';
import {
  loginApi,
  resetPasswordApi,
  signUpApi,
  forgotPasswordApi
} from './modules/auth';
import { productApi } from './modules/products';
import { companyProductApi } from './modules/companyProducts';
import { commissionApi } from './modules/commission';
import { proposalApi } from './modules/proposal';
import { companyApi } from './modules/company';
import { userApi } from './modules/user';
import { catalogApi } from './modules/catalog';
import { applicationApi } from './modules/application';
import { quoteApi } from './modules/quote';
import { serviceRequestApi } from './modules/serviceRequest';
import { dictonaryServiceApi } from './modules/dictonaryService';
import { policyApi } from './modules/policy';
import { paymentApi } from './modules/payment';
import { reportApi } from './modules/report';
import { shareApplicationApi } from './modules/shareApplication';
import { distributionsApi,formJsonSlice} from './modules/distribution';
import { documentTemplateApi } from './modules/documentTemplate';
import { configurationApi } from './modules/configuration';

const listenerMiddleware = createListenerMiddleware();

const rootReducer = combineReducers({
  // ... (previous rootReducer)
  [modalSlice.name]: modalSlice.reducer,
  [loaderSlice.name]: loaderSlice.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [formJsonSlice.name]: formJsonSlice.reducer,
  [currentEditorSlice.name]: currentEditorSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
  [dictonaryServiceApi.reducerPath]: dictonaryServiceApi.reducer,
  [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [catalogApi.reducerPath]: catalogApi.reducer,
  [companyProductApi.reducerPath]: companyProductApi.reducer,
  [commissionApi.reducerPath]: commissionApi.reducer,
  [proposalApi.reducerPath]: proposalApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [quoteApi.reducerPath]: quoteApi.reducer,
  [serviceRequestApi.reducerPath]: serviceRequestApi.reducer,
  [shareApplicationApi.reducerPath]: shareApplicationApi.reducer,
  [distributionsApi.reducerPath]: distributionsApi.reducer,
  [documentTemplateApi.reducerPath]: documentTemplateApi.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer
});

const persistConfig = {
  key: 'root', // key for localStorage key
  storage, // chosen storage engine (e.g., localStorage)
  whitelist: ['appConfig'] // array of reducers to persist
  // blacklist: [], // optional - reducers to ignore
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer, // Use the persisted rootReducer

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare()
      .prepend(listenerMiddleware.middleware)
      .concat(
        configApi.middleware,
        loginApi.middleware,
        signUpApi.middleware,
        resetPasswordApi.middleware,
        forgotPasswordApi.middleware,
        productApi.middleware,
        catalogApi.middleware,
        companyProductApi.middleware,
        dictonaryServiceApi.middleware,
        commissionApi.middleware,
        proposalApi.middleware,
        companyApi.middleware,
        userApi.middleware,
        applicationApi.middleware,
        quoteApi.middleware,
        serviceRequestApi.middleware,
        policyApi.middleware,
        reportApi.middleware,
        paymentApi.middleware,
        shareApplicationApi.middleware,
        distributionsApi.middleware,
        documentTemplateApi.middleware,
        configurationApi.middleware
      ),
  devTools: true
});

// TODO: need to remove all listeners and move to RTK Queries
// add all listeners
// [...configListeners].map((sl) => {
//   listenerMiddleware.startListening(sl);

//   return true;
// });

// Initialize RTK listeners
setupListeners(store.dispatch);
export const persistor = persistStore(store);
