import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const mgasRoute = [
  {
    path: '',
    async lazy() {
      const  { Mga } = await import("../pages/mgas/All/Mgas");
      return { Component: () => suspenseWrapper(<Mga />) };
    }
  },
  {
    path: 'rating-systems',
    async lazy() {
      const  { RatingSystem } = await import("../pages/mgas/RatingSystems/RatingSystems");
      return { Component: () => suspenseWrapper(<RatingSystem />) };
    }
  },
  {
    path: 'mga-users',
    async lazy() {
      const  { MgaUser} = await import("../pages/mgas/MgaUsers/MgaUsers");
      return { Component: () => suspenseWrapper(<MgaUser />) };
    }
  },
  {
    path: 'authorized-carriers',
    async lazy() {
      const  { AuthorizedCarriers} = await import("../pages/mgas/AuthorizedCarriers/AuthorizedCarriers");
      return { Component: () => suspenseWrapper(<AuthorizedCarriers />) };
    }
  },
  {
    path: 'authorized-mga',
    async lazy() {
      const  { AuthorizedMga } = await import("../pages/products/AuthorizedMga/AuthorizedMga");
      return { Component: () => suspenseWrapper(<AuthorizedMga />) };
    }
  }, 
   {
    path: 'authorized-products',
    async lazy() {
      const  { AuthorizedProductsPage } = await import("../pages/products/AuthorizedProducts/AuthorizedProducts");
      return { Component: () => suspenseWrapper(<AuthorizedProductsPage />) };
    }
  }
];
