import React from 'react';

import { suspenseWrapper } from 'utils/suspenseWrapper';

export const distributionRoute = [
  {
    path: '',
    async lazy() {
      const { Distribution } = await import('../pages/distribution/Distribution/Distribution');
      return {Component: () => suspenseWrapper(<Distribution />)};
    }
  },

  {
    path: 'binding',
    async lazy() {
      const { Bindings } = await import('../pages/distribution/Binding/Binding');
      return {Component: () => suspenseWrapper(<Bindings />)};
    }
  },
  {
    path: 'policy',
    async lazy() {  
      const { Policies } = await import('../pages/distribution/Policy/Policy');
      return {Component: () => suspenseWrapper(<Policies />)};
    }
  },
  {
    path: 'mga-submission',
    async lazy() {
      const { MgaSubmission } = await import('../pages/distribution/MgaSubmission/MgaSubmission');
      return {Component: () => suspenseWrapper(<MgaSubmission />)};
    }
  },
  {
    path: 'payment',
    async lazy() {
      const { Payments } = await import('../pages/distribution/Payment/Payment');
      return {Component: () => suspenseWrapper(<Payments />)};
    }
  },
  {
    path: 'application-forms',
    async lazy() {
      const { ApplicationForms } = await import('../pages/distribution/ApplicationForms/ApplicationForms');
      return {Component: () => suspenseWrapper(<ApplicationForms />)};
    }
  },
  {
    path: 'data-collection',
    async lazy() {
      const { DataCollection } = await import('../pages/DistributionManagement/DataCollection/DataCollection');
      return {Component: () => suspenseWrapper(<DataCollection />)};
    }
  },
  {
    path: 'indications',
    async lazy() {
      const { Indication } = await import('../pages/DistributionManagement/Indications/Indications');
      return {Component: () => suspenseWrapper(<Indication />)};
    }
  },
  {
    path: 'indication-request',
    async lazy() {
      const { IndicationRequest } = await import('../pages/DistributionManagement/IndicationRequest/IndicationRequest');
      return {Component: () => suspenseWrapper(<IndicationRequest />)};
    }
  },
  {
    path: 'quote-indication',
    async lazy() {
      const { QuoteIndication } = await import('../pages/distribution/QuoteIndication/QuoteIndication');
      return {Component: () => suspenseWrapper(<QuoteIndication />)};
    }
  },
  {
    path: 'quote-request',
    async lazy() {
      const { QuoteRequest } = await import('../pages/DistributionManagement/QuoteRequest/QuoteRequest');
      return {Component: () => suspenseWrapper(<QuoteRequest />)};
    }
  },
  {
    path: 'review-quotes',
    async lazy() {
      const { ReviewQuotes } = await import('../pages/DistributionManagement/ReviewQuotes/ReviewQuotes');
      return {Component: () => suspenseWrapper(<ReviewQuotes />)};
    }
  }

];

export const distributionListRoute = [
  {
    path: '',
    async lazy() {
      const { DistributionManagement } = await import('../pages/DistributionManagement/index');
      return {Component: () => suspenseWrapper(<DistributionManagement />)};
    }
  },
  {
    path: 'data-collection',
    async lazy() {
      const { DataCollection } = await import('../pages/DistributionManagement/DataCollection/DataCollection');
      return {Component: () => suspenseWrapper(<DataCollection />)};
    }
  },
  {
    path: 'data-collection/add-questions',
    async lazy() {
      const { AddQuestions } = await import('../pages/DistributionManagement/DataCollection/AddQustions');
      return {Component: () => suspenseWrapper(<AddQuestions />)};
    }
  },
  {
    path: 'indications',
    async lazy() {
      const { Indication } = await import('../pages/DistributionManagement/Indications/Indications');
      return {Component: () => suspenseWrapper(<Indication />)};
    }
  },
  {
    path: 'indication-request',
    async lazy() {
      const { IndicationRequest } = await import('../pages/DistributionManagement/IndicationRequest/IndicationRequest');
      return {Component: () => suspenseWrapper(<IndicationRequest />)};
    }
  },
  // {
  //   path: 'summary',
  //   async lazy() {
  //     const { Summary } = await import('../pages/DistributionManagement/Summary/Summary');
  //     return {Component: () => suspenseWrapper(<Summary />)};
  //   }
  // },
  {
    path: 'quote-indication',
    async lazy() {
      const { QuoteIndication } = await import('../pages/distribution/QuoteIndication/QuoteIndication');
      return {Component: () => suspenseWrapper(<QuoteIndication />)};
    }
  },
  {
    path: 'quote-request',
    async lazy() {
      const { QuoteRequest } = await import('../pages/DistributionManagement/QuoteRequest/QuoteRequest');
      return {Component: () => suspenseWrapper(<QuoteRequest />)};
    }
  },
  {
    path: 'review-quotes',
    async lazy() {
      const { ReviewQuotes } = await import('../pages/DistributionManagement/ReviewQuotes/ReviewQuotes');
      return {Component: () => suspenseWrapper(<ReviewQuotes />)};
    }
  },
  {
    path: 'payments',
    async lazy() {
      const { Payment } = await import('../pages/DistributionManagement/Payment/Payment');
      return {Component: () => suspenseWrapper(<Payment />)};
    }
  },
  {
    path: 'policies',
    async lazy() {
      const { Policy } = await import('../pages/DistributionManagement/Policy/Policy');
      return {Component: () => suspenseWrapper(<Policy />)};
    }
  }
];
