import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const configurationApi = createApi({

  reducerPath: 'configurationApi',
  tagTypes: ['POST'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({

    getProductStat: builder.query({
      query: (payload) => ({
        url: `/statistics/get-all-product-stats`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    })   
     
  })
});
export const { useGetProductStatQuery } = configurationApi;
