import React from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectconfigDataState } from 'redux/modules/config';
import { useGetDistributionDetailsQuery } from 'redux/modules/distribution';
import { useAuth } from 'hooks/useAuth';
import FetchingLaoder from 'components/Loader/FetchingLoader';

import { CardContainer } from '../CustomCard';


const TableAccordion = ({ header, row, isExpanded, onToggleExpand }) => {

  const { user,logout } = useAuth();
  const configData = useSelector(selectconfigDataState);

  const { newDistributionCards } = configData;

  const { 
    data: distributionDetails, 
    error: distributionError,
    isFetching
  } = useGetDistributionDetailsQuery({ token: user.token, appId: row.applicationid }, { skip: !isExpanded });

  if (distributionError?.status === 401) {
    logout();
  }

  const handleToggle = () => {
    onToggleExpand();
  };

  return (
    <>
      <TableRow>
        {header.map((tableHead) => (
          <TableCell
            key={`main-${tableHead.id}`}
            align={tableHead.align ? tableHead.align : 'left'}
            sx={{
              color: "#4E5B6E",
              fontSize: "0.875rem"
            }}    
          >
            {row[tableHead.id]}
          </TableCell>
        ))}

        <TableCell>
          <IconButton disableRipple onClick={handleToggle} sx={{p:0, pr: 0.5}}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>

      </TableRow>

      {/* Expanded row */}
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={header.length + 1} className='expandRowCell'>

          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            { isFetching ? 
              ( <Box sx={{ 
                     height: '10rem', display: 'flex', alignItems: 'center' 
                }}> 
                  <FetchingLaoder /> 
                </Box>
              ) :
              (<CardContainer distributionSteps={newDistributionCards} cardData={distributionDetails?.result} />)
            }
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableAccordion;
