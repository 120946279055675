/* eslint-disable no-nested-ternary */
import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CheckIcon from '@mui/icons-material/Check';

import { StatusChip } from 'components/StatusChip';
import { CARRIER_STATE, SCREEN } from 'constant/screenConstant';
import { CARRIER_STATUS_COLOR } from 'constant/colorConstant';
import { ProgressBar } from 'components/ProgressBar';

export function CustomCard({ cardData, distributionSteps }) {

    const steps = Object.values(distributionSteps);

    const getStatusColor = (status) => {

        let bgHex = '';
        let colorHex = '';

        switch (status) {
            case 'success':
                bgHex = CARRIER_STATUS_COLOR.SUCCESS_BG;
                colorHex = CARRIER_STATUS_COLOR.SUCCESS_TEXT;
                break;
            case 'warning':
                bgHex = CARRIER_STATUS_COLOR.WARNING_BG;
                colorHex = CARRIER_STATUS_COLOR.WARNING_TEXT;
                break;
            case 'error':
                bgHex = CARRIER_STATUS_COLOR.ERROR_BG;
                colorHex = CARRIER_STATUS_COLOR.ERROR_TEXT;
                break;
            default:
                bgHex = CARRIER_STATUS_COLOR.DEFAULT_BG;
                colorHex = CARRIER_STATUS_COLOR.DEFAULT_TEXT;
                break;
        }

        return { bgHex, colorHex };
    };

    const renderListItem = (item) => (
        <ListItem key={item.name} disablePadding>
            <ListItemIcon sx={{
                minWidth: "0.65rem",
                color: "#4E5B6E"
            }}>
                <FiberManualRecordIcon sx={{ fontSize: '0.5rem', margin: '0 0.4rem' }} />
            </ListItemIcon>

            <ListItemText
                primary={
                    <Typography sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        color: "#4E5B6E",
                        fontSize: "0.8125rem"
                    }}>
                        <span style={{
                            width: '60%', whiteSpace: "nowrap",
                            overflow: "hidden", textOverflow: "ellipsis"
                        }}>
                            {item.carrierName}
                        </span>

                        {(item.status && cardData?.screen !== steps[2]) && (
                            <StatusChip
                                label={item.status}
                                color='custom'
                                customColor={getStatusColor(
                                    (item.status === CARRIER_STATE.QUOTE || item.status === CARRIER_STATE.BIND) ? 'success' :
                                        (item.status === CARRIER_STATE.FAILED || item.status === CARRIER_STATE.DECLINED) ? 'error' :
                                            'warning'
                                )}
                                borderRadius="1rem"
                                fontSize="0.75rem"
                                fontWeight="500"
                                height="1.25rem"
                            />
                        )}
                    </Typography>
                }
            />
        </ListItem>
    );

    const renderList = (section, index) => (
        <div key={section.productName} style={{ marginBottom: (index === cardData.indications.length - 1) ? '0' : '0.75rem' }}>
            <Typography sx={{
                color: "#2C3543",
                fontSize: "0.8125rem",
                fontWeight: 500
            }}>
                {section.productName}
            </Typography>

            <List sx={{ padding: 0 }}>
                {section?.indications?.map((item) => (
                    renderListItem(item)
                ))}
            </List>
        </div>
    );

    const checkedIcon = () => (<CheckIcon sx={{ fontSize: '0.75rem !important', color: '#027A48 !important' }} />);

    const pendingIcon = (color) => (<QueryBuilderIcon sx={{ fontSize: '0.75rem !important', color: color || '#D98C00 !important' }} />);

    const renderBindCard = (section, index) => {

        let signatureState = '';
        let paymentState = '';
        let policyState = '';

        if (section.status === CARRIER_STATE.FAILED) {
            signatureState = 'error';
            paymentState = '';
            policyState = '';
        }
        else if (section.status === CARRIER_STATE.QUOTE) {
            signatureState = 'warning';
            paymentState = '';
            policyState = '';
        }
        else if (section?.currentScreen === SCREEN.DOWNLOAD_POLICY && section?.screenState === 'success') {
            signatureState = 'success';
            paymentState = 'success';
            policyState = 'success';
        }
        else if (section?.currentScreen === SCREEN.DOWNLOAD_POLICY && section?.screenState !== 'success') {
            signatureState = 'success';
            paymentState = 'success';
            policyState = 'warning';
        }
        else if (section?.currentScreen === SCREEN.PAYMENT && section?.screenState === 'success') {
            signatureState = 'success';
            paymentState = 'success';
            policyState = 'warning';
        }
        else if (section?.currentScreen === SCREEN.PAYMENT && section?.screenState !== 'success') {
            signatureState = 'success';
            paymentState = 'warning';
            policyState = '';
        }
        else if ((section?.currentScreen === SCREEN.SIGN_PROPOSAL || section?.currentScreen === SCREEN.SIGNATURE) && section?.screenState === 'success') {
            signatureState = 'success';
            paymentState = 'warning';
            policyState = '';
        }
        else if ((section?.currentScreen === SCREEN.SIGN_PROPOSAL || section?.currentScreen === SCREEN.SIGNATURE) && section?.screenState !== 'success') {
            signatureState = 'warning';
            paymentState = '';
            policyState = '';
        }
        else {
            signatureState = '';
            paymentState = '';
            policyState = '';
        }

        return (
            <Card key={section.carrierName} sx={{ mb: (index === cardData.indications.length - 1) ? '0' : '0.75rem' }}>
                <CardContent sx={{ padding: '0.75rem !important' }}>

                    <Typography sx={{
                        color: "#2C3543",
                        fontSize: "0.8125rem",
                        fontWeight: 500
                    }}>
                        {section.productName}
                    </Typography>

                    <Typography sx={{
                        color: "#4E5B6E",
                        fontSize: "0.8125rem"
                    }}>
                        {section.carrierName}
                    </Typography>

                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem'
                    }}>
                        <StatusChip
                            label="Signature"
                            color='custom'
                            customColor={getStatusColor(signatureState)}
                            borderRadius="1rem"
                            fontSize="0.7rem"
                            fontWeight="500"
                            height="1.25rem"
                            icon={signatureState ?
                                signatureState === 'success' ? checkedIcon() :
                                    pendingIcon(signatureState === 'error' ? '#F64B3A !important' :
                                        '') : {}
                            }
                        />

                        <StatusChip
                            label="Payment"
                            color='custom'
                            customColor={getStatusColor(paymentState)}
                            borderRadius="1rem"
                            fontSize="0.7rem"
                            fontWeight="500"
                            height="1.25rem"
                            icon={paymentState ? paymentState === 'success' ? checkedIcon() : pendingIcon() : {}}
                        />

                        <StatusChip
                            label="Policy"
                            color='custom'
                            customColor={getStatusColor(policyState)}
                            borderRadius="1rem"
                            fontSize="0.7rem"
                            fontWeight="500"
                            height="1.25rem"
                            icon={policyState ? policyState === 'success' ? checkedIcon() : pendingIcon() : {}}
                        />
                    </Box>

                </CardContent>
            </Card>
        );
    };

 
    return (
        <>
            {cardData?.screen === steps[0] &&
                <Card>
                    <CardContent sx={{ padding: '0.75rem !important' }}>
                        <ProgressBar showLabel="true" value={cardData?.indications?.fromCompletionPercentage} />                  
                    </CardContent>
                </Card>
            }

            {(cardData?.screen === steps[1] || cardData?.screen === steps[2]) &&
                <Card>
                    {cardData?.indications?.length > 0 &&
                        <CardContent sx={{ padding: '0.75rem !important' }}>

                            {cardData?.screen === steps[2] &&
                                <ProgressBar showLabel="true" value={cardData?.fromCompletionPercentage} />                    
                            }
                            {cardData?.indications?.map((section, index) => renderList(section, index))}
                        </CardContent>
                    }

                    {cardData?.indications?.length === 0 &&
                        <CardContent sx={{ padding: '2rem 0.75rem !important', textAlign: 'center' }}>
                            <Typography sx={{
                                color: "#2C3543",
                                fontSize: "0.8125rem",
                                fontWeight: 500
                            }}>
                                No data here!
                            </Typography>
                        </CardContent>
                    }
                </Card>
            }

            {cardData?.screen === steps[3] &&
                cardData?.indications?.map((section, index) => renderBindCard(section, index))
            }
        </>
    );
};

