import { useState } from 'react';
import AWS from 'aws-sdk';
import { useSelector } from 'react-redux';

import {
  S3_BUCKET_ACCESS_KEY,
  S3_BUCKET_SECRET_ACCESS_KEY
} from 'constant/apiConstant';
import { selectconfigDataState } from 'redux/modules/config';

AWS.config.update({
  accessKeyId: S3_BUCKET_ACCESS_KEY,
  secretAccessKey: S3_BUCKET_SECRET_ACCESS_KEY
});

export const useAwsS3 = () => {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const configData = useSelector(selectconfigDataState);
  const myBucket = new AWS.S3({
    params: { Bucket: configData?.bucketName },
    region: configData?.bucketRegion
  });

  const uploadFile = (file, path) => {
    setIsLoading(true);
    const fileName = `${Date.now()}-${file?.name.replace(/ /g, '')}`;
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: `${configData?.bucketName}/${path}`,
      Key: fileName
    };

    const fileUrl = `https://${configData?.bucketName}.s3.${configData?.bucketRegion}.amazonaws.com/${path}/${fileName}`;

    return new Promise((resolve, reject) => {
      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          setIsLoading(false); // Set isLoading to false after upload completion
          if (err) reject(err);

          resolve(fileUrl);
        });
    });
  };

  // delete file using url 

  const deleteFile = (fileUrl) => {
    const fileName = fileUrl.split('/').pop();
    const params = {
      Bucket: configData?.bucketName,
      Key: fileName
    };
    return new Promise((resolve, reject) => {
      myBucket
        .deleteObject(params)
        .send((err, data) => {
          if (err) reject(err);
          resolve(data);
        });
    });
  };  


  return {
    progress,
    uploadFile,
    isLoading,
    deleteFile
  };
};
