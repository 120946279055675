import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const signUpApi = createApi({
  reducerPath: 'signUpApi',
  tagTypes: ['Post'],
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    postSignUp: builder.mutation({
      query: (payload) => ({
        url: 'onboarding/request-access',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    }),
    agentSignUp: builder.mutation({
      query: (payload) => ({
        url: 'onboarding/agency-signup',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const { usePostSignUpMutation, useAgentSignUpMutation } = signUpApi;
