import { createSlice } from '@reduxjs/toolkit';

export const initialLoaderState = { formJson: {} };

export const formJsonSlice = createSlice({
  name: 'formJson',
  initialState: initialLoaderState,
  reducers: {
    setFormJson(state, action) {
      return action.payload;
    }
  }
});

export const { setFormJson } = formJsonSlice.actions;

export const selectedFormJson = (state) => state.formJson;
