import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import { AUTH_ROLES } from "constant/authRole";
import { setApiData } from "redux/modules/config";
import { useAuth } from "hooks/useAuth";

const navLinkStyle = {
  borderRadius: '10px', '&:hover': {  bgcolor:'#ECF4FF' },  '&.Mui-selected': {bgcolor: '#ECF4FF'} 
 };

export const SingleLevel = ({ item, isMenuDisabled, isDisabled }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {user} = useAuth();
    const pathname = window.location.pathname.split('/')[1];

    const isMatch = (url) => url.includes(pathname);

return (
    <ListItem key={`key--${item.title}`} disablePadding>
      <ListItemButton
        onClick={() => {
          setTimeout(() => {
            if (user.isParent || user.role.slug === AUTH_ROLES.PORTAL_ADMIN) {
              navigate(item.link);
              dispatch(setApiData({ limit: 25, offset: 0 }));
            } else {
              navigate({
                pathname: item.link,
                search: `?${createSearchParams({
                  companyId: user.company.companyId,
                  limit: 5,
                  offset: 0
                })}`
              });
            }
          }, 250);
        }}
        selected={isMatch(item.link)}
        sx={navLinkStyle}       
         disabled={isDisabled || isMenuDisabled}
      >
        <ListItemIcon sx={{ minWidth: '35px' }}>
          {isMatch(item.link) ? (
            <img src={item.iconActive} alt={item.title} />
          ) : (
            <img src={item.icon} alt={item.title} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              fontSize='.875rem'
              color={isMatch(item.link) ? '#0B1524' : '#4E5B6E'}
             fontWeight={500}
            >
              {item.title}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};