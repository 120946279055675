/* eslint-disable object-curly-newline */
import { createTheme } from '@mui/material';

import {
  blackColor,
  darkGreyColor,
  lightGreyColor,
  mediumGreyColor,
  primaryColor,
  primaryHoverColor,
  secondaryColor,
  whiteColor
} from './colors';
import { subtitle1, caption, h6 } from './typography';

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1100,
    lg: 1324,
    xl: 1920
  }
};

export const theme = createTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    lightGrey: { main: darkGreyColor }
  },
  breakpoints: customBreakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: { '*': { fontFamily: 'Roboto, sans-serif' } }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            color: whiteColor,
            backgroundColor: primaryColor,
            textTransform: 'initial',
            '&:hover': { backgroundColor: primaryHoverColor },
            '&:disabled': { backgroundColor: lightGreyColor }
          }
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: blackColor,
            backgroundColor: secondaryColor,
            textTransform: 'initial',
            fontWeight: 600,
            border: `1px solid ${darkGreyColor}`,
            '&:hover': {
              border: `1px solid ${primaryColor}`,
              backgroundColor: secondaryColor,
              color: primaryColor,
              '.MuiButton-startIcon path[\'stroke-linejoin="round"\']': {
                stroke: 'red'
              }
            },
            '&:disabled': { border: `1px solid ${mediumGreyColor}` }
          }
        }
      ]
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          border: '1px solid #E7E8E9',
          fontSize: '.875rem',
          color: blackColor,  // Replace with blackColor variable if defined globally
          backgroundColor: '#F7F9FB'
        },
        deleteIcon: {
          color: '#999',
          '&:hover': {
            color: '#333'
          }
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          margin: '0px 10px',
          color: ownerState.selected ? blackColor : darkGreyColor,
          fontWeight: 'bold',
          '&.Mui-selected': {
            color: ownerState.selected ? blackColor : darkGreyColor
          }
        })
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          background: whiteColor,
          boxShadow: '0px 4px 6px #7F7E8912',
          borderRadius: '4px'
        })
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: () => ({
          color: blackColor,
          font: 'normal normal bold 20px/26px Roboto'
        })
      }
    }
  }
});

theme.typography.caption = caption(theme);
theme.typography.subtitle1 = subtitle1(theme);
theme.typography.h6 = h6(theme);
