/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialConfigState = {
  language: 'en',
  data: null,
  apiData: { 
    offset: 0, limit: 25,
    agencyId: { label: '', value: '' },
    productId: { label: '', value: '' },
    effectiveDate: '',
    expirationDate: '',
    applicationStatus: { label: '', value: '' },
    carrierId: { label: '', value: '' },
    requestStatus: { label: '', value: '' },
    requestType: { label: '', value: '' },
    accountId: { label: '', value: '' },
    agentId: { label: '', value: '' },
    searchBy:'',
    archived:'',
    active:'',
    naics:{name:'',value:''},
    product:{name:'',value:''},
    carrier:{name:'',value:''}

  },
  currentScreen:null
};

// Demo Redux Slice
export const configSlice = createSlice({
  name: 'appConfig',
  initialState: initialConfigState,
  reducers: {
    setConfigData(state, action) {
      state.data = action.payload;
    },
    setApiData(state, action) {
      state.apiData = action.payload;
    },
    setCurrentScreen(state, action) {
      state.currentScreen = action.payload;
    }
  }
});

export const { setConfigData } = configSlice.actions;
export const { setApiData } = configSlice.actions;
export const { setCurrentScreen } = configSlice.actions;

export const selectconfigDataState = (state) => state.appConfig?.data;
export const selectApiData = (state) => state.appConfig?.apiData;
export const selectCurrentScreen = (state) => state.appConfig?.currentScreen;

export const selectLanguageState = (state) => state?.appConfig?.language;
