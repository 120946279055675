/* eslint-disable no-nested-ternary */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useSelector } from 'react-redux';
import { Pagination, PaginationItem, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { selectApiData } from 'redux/modules/config';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, marginLeft: 'auto' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function NumberPaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  return (
    <Box sx={{ flexShrink: 0, marginLeft: 'auto' }}>
      <Pagination
        color="primary"
        shape="rounded"
        count={Math.ceil(count / rowsPerPage) || 1}
        page={page + 1}
        onChange={(event, value) => onPageChange(event, value - 1)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
        sx={{
          '.MuiPaginationItem-previousNext': { border: '1px solid #D1D6E0' },
          '.Mui-selected': {
            color: '#3487F3',
            background: '#ECF4FF'
        
          }
        }}
      />
    </Box>
  );
}

NumberPaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export const CustomPaginationActionsTable = ({
  totalRecords,
  onPageChange,
  dataType,
  showNumberPagination
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { limit, offset } = useSelector(selectApiData);

  React.useEffect(() => {
    if (offset !== null && limit !== null) {
      setPage(offset / limit);
    } else {
      setPage(0);
    }
  }, [offset]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newPageRown = limit || rowsPerPage;
    setRowsPerPage(newPageRown);
    onPageChange(newPage, newPageRown);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPageChange(0, parseInt(event.target.value, 10));
  };

  const labelDisplayedRows = ({ from, to, count }) => (
    <Typography sx={{ color: '#4E5B6E', fontSize: '0.875rem' }}>
      Showing
      <span style={{ color: '#5B616B', fontWeight: '700' }}>
        {' '}
        {from} - {to}{' '}
      </span>{' '}
      of
      <span style={{ color: '#5B616B', fontWeight: '700' }}> {count} </span>
    </Typography>
  );

  return (
    <TablePagination
      sx={{
        '.MuiTablePagination-toolbar': { paddingLeft: '12px !important' },
        '.MuiTablePagination-spacer': { flex: 0 },
        '.MuiTablePagination-selectLabel': {},
        '.MuiNativeSelect-select': { padding: '2px 34px 2px 8px !important' }
      }}
      // labelDisplayedRows={() => ''}
      rowsPerPageOptions={
        dataType === 'newDistributionData'
          ? [25, 50, 75, 100, 500, 1000]
          : dataType === 'distributionData'
          ? [50, 75, 100, 500, 1000]
          : [25, 50, 100, 500, 1000]
      }
      count={totalRecords || 0}
      rowsPerPage={limit !== null ? limit : rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
        variant: 'outlined',
        sx: {
          paddingTop: '8px !important',
          paddingBottom: '8px'
        }
      }}
      labelDisplayedRows={labelDisplayedRows}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={
        showNumberPagination ? NumberPaginationActions : TablePaginationActions
      }
    />
  );
};
